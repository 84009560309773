import { StyleProvider } from '@ant-design/cssinjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { App as AntApp, ConfigProvider, type ThemeConfig } from 'antd';
import ptBR from 'antd/locale/pt_BR';
import { useLayoutStore } from 'features/layout/store';
import { useSistemaStore } from 'features/sistema/store';
import { changeManifestImgs, setColors } from 'features/sistema/util';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router, router } from 'router/Router';
import { store } from 'state/store';
import { useEffectOnce } from 'std/hooks/useEffectOnce';
import { isFirefox } from 'std/platform';
import { isLocalHost, isSandbox } from 'std/url';
import { changeFavIcon } from 'std/util';
import { queryClient } from './queryClient';

const TanStackRouterDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : lazy(() =>
              import('@tanstack/router-devtools').then((res) => ({
                  default: res.TanStackRouterDevtools,
              })),
          );

const ReactQueryDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : lazy(() =>
              import('@tanstack/react-query-devtools').then((res) => ({
                  default: res.ReactQueryDevtools,
              })),
          );

const versionCheckInterval = 1000 * 60 * 10;

export function App() {
    const downloadSistema = useSistemaStore((s) => s.downloadSistema);
    const sistema = useSistemaStore((s) => s.sistema);

    const [theme, setTheme] = useState<ThemeConfig>();

    useEffect(() => {
        if (!sistema) {
            return;
        }

        changeFavIcon(sistema.sis_logotipo || '');
        changeManifestImgs(sistema.sis_nome_sistema);
        setColors(sistema.layout);

        setTheme({
            token: {
                colorPrimary: sistema.layout.sic_cor_primaria,
                fontFamily: 'mukta',
            },
            components: {
                Breadcrumb: {
                    colorText: sistema.layout.sic_menu_titulo,
                },
                Button: {
                    colorTextLightSolid: sistema.layout.sic_botao_texto,
                },
                Switch: {
                    colorTextLightSolid: sistema.layout.sic_botao_texto,
                },
            },
        });
    }, [sistema]);

    useEffectOnce(() => {
        downloadSistema();
    });

    const sandbox = isSandbox();

    const fetchRemoteVersion = useCallback(async () => {
        const url = sandbox
            ? 'https://s3.sa-east-1.amazonaws.com/sandbox.app.mercurioapp.com.br/version.json'
            : 'https://s3.sa-east-1.amazonaws.com/app.mercurioapp.com.br/version.json';

        const response = await fetch(url, {
            cache: 'no-store',
        });

        const json = await response.json();
        useLayoutStore.setState({ newVersion: json.version });
        return json.version;
    }, [sandbox]);

    useEffect(() => {
        // Ativar checagem de atualizações no firefox em sandbox e produção
        if (isLocalHost() || !isFirefox()) {
            return;
        }

        const timer = setInterval(() => {
            fetchRemoteVersion();
        }, versionCheckInterval);

        return () => clearInterval(timer);
    }, [fetchRemoteVersion]);

    return (
        <HelmetProvider>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider theme={theme} locale={ptBR}>
                        <StyleProvider layer={true}>
                            <AntApp>
                                <Router />
                            </AntApp>
                        </StyleProvider>
                    </ConfigProvider>

                    <Suspense fallback={null}>
                        <TanStackRouterDevtools router={router} position='bottom-right' />
                        <ReactQueryDevtools />
                    </Suspense>
                </QueryClientProvider>
            </Provider>
        </HelmetProvider>
    );
}
