import { Helmet } from 'components-old/merc/Helmet';
import { useLayoutStore } from 'features/layout/store';
import { useEffectOnce } from 'std/hooks/useEffectOnce';

type HelmetWithBreadcrumbsProps = {
    title: string;
    breadcrumbs?: string[];
};

export const useSetBreadcrumbs = (breadcrumbs: string[] | undefined) => {
    const currentBreadcrumbs = useLayoutStore((s) => s.breadcrumbs);

    const setBreadCrumbs = () => {
        if (JSON.stringify(breadcrumbs) === JSON.stringify(currentBreadcrumbs)) {
            return;
        }

        useLayoutStore.setState({ breadcrumbs });
    };

    useEffectOnce(setBreadCrumbs);
};

export function HelmetWithBreadcrumbs(props: HelmetWithBreadcrumbsProps) {
    const { title, breadcrumbs } = props;
    useSetBreadcrumbs(breadcrumbs);
    return <Helmet title={title} />;
}
