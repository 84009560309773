import { Outlet, createRootRoute, redirect, useRouterState } from '@tanstack/react-router';
import { useLoginStore } from 'features/login/store';
import { useSistemaStore } from 'features/sistema/store';
import NProgress from 'nprogress';
import { Error404 } from 'router/Error404';
import { Splash } from 'router/Splash';
import { oldPublicRoutesBackwardsCompatibility } from 'router/backwards-compatibility/old-public-routes-backwards-compatibility';
import { goToLogin } from 'router/util';

let previousPath = '';

export const Route = createRootRoute({
    beforeLoad: ({ location }) => {
        // Em caso de rota com # (rota antiga) corrigir pra rota correta
        if (location.href.startsWith('/#/')) {
            oldPublicRoutesBackwardsCompatibility(location.hash);
        }

        // Rota pública, não fazer nada
        if (location.pathname.startsWith('/p/')) {
            return;
        }

        if (goToLogin()) {
            throw redirect({ to: '/auth/login' });
        }

        if (location.pathname === '/') {
            const isFintech = useLoginStore.getState().getEmpresa()?.emp_sistema_tipo_uso === 'F';

            if (isFintech) {
                throw redirect({ to: '/a/movimentacoes' });
            }

            throw redirect({ to: '/a/painel-gerencial' });
        }
    },
    component: () => {
        const { location } = useRouterState();
        const splash = useSistemaStore((s) => s.splash);

        if (location.pathname !== previousPath) {
            window.scrollTo(0, 0);
            NProgress.start();
        }

        setTimeout((): void => {
            NProgress.done();
            previousPath = location.pathname;
        }, 300);

        if (splash) {
            return <Splash />;
        }

        return <Outlet />;
    },
    notFoundComponent: () => <Error404 />,
});
