import { onlyNumbers } from 'std/format';

function sameDigit(value: string): boolean {
    const first = value[0];

    if (!first) {
        return false;
    }

    for (let i = 1; i < value.length; i += 1) {
        if (value[i] !== first) {
            return false;
        }
    }

    return true;
}

// https://www.geradorcnpj.com/javascript-validar-value.htm
export function isCNPJ(value: string): boolean {
    const cnpj = value.replace(/[^\d]+/g, '');

    if (cnpj === '' || cnpj.length !== 14) {
        return false;
    }

    // Se todos digitos forem iguais é invalido
    if (sameDigit(cnpj)) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos;
        pos -= 1;
        if (pos < 2) {
            pos = 9;
        }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== Number(digitos.charAt(0))) {
        return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos;
        pos -= 1;
        if (pos < 2) {
            pos = 9;
        }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== Number(digitos.charAt(1))) {
        return false;
    }

    return true;
}

export function isCPF(value: string): boolean {
    const input = onlyNumbers(value);

    // Se todos digitos forem iguais é invalido
    if (sameDigit(input)) {
        return false;
    }

    let soma = 0;

    for (let i = 1; i <= 9; i += 1) {
        soma += Number.parseInt(input.substring(i - 1, i), 10) * (11 - i);
    }

    let resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }

    if (resto !== Number.parseInt(input.substring(9, 10), 10)) {
        return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i += 1) {
        soma += Number.parseInt(input.substring(i - 1, i), 10) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }

    if (resto !== Number.parseInt(input.substring(10, 11), 10)) {
        return false;
    }

    return true;
}

export function isEmail(value: string | null | undefined): boolean {
    if (!value) {
        return false;
    }

    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(value);
}

export function isTelefone(value: string | number): boolean {
    const innerValue = typeof value === 'string' ? value : value.toString();
    return innerValue.length > 9 && innerValue.length < 12;
}

export function isCEP(value: string): boolean {
    return value.length === 8;
}

export function validateImage(file: File, size: number): boolean {
    const isLt2M = file.size / 1024 / 1024 < size;
    return isLt2M;
}

export function isChaveAcesso(value: string): boolean {
    // se precisar de mais validações: https://wiki.centrium.com.br/nota-fiscal/validacao-da-chave-de-acesso-na-nota-de-entrada/
    return value.length === 44;
}

export function isDate(value: string): boolean {
    return value.match(/\d{1,2}\/\d{1,2}\/\d{2,4}/) !== null;
}
