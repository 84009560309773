import { YoutubeOutlined } from '@ant-design/icons';
import { Button, Col, Grid, Row } from 'antd';
import { useSistemaStore } from 'features/sistema/store';
import { SelectEmpresas } from './SelectEmpresas';
import { UserMenu } from './UserMenu/UserMenu';

export function TopBar() {
    const youtube = useSistemaStore((s) => s.sistema?.sis_youtube);
    const grid = Grid.useBreakpoint();
    const exibirBotaoYoutube = typeof youtube === 'string' && youtube.length > 0 && grid.md;

    return (
        <Row className='flex flex-row items-center h-16 px-8 pt-0 m-0 border-b border-gray-200 border-solid shadow-sm min-h-16 flex-nowrap'>
            <Col xs={20} sm={17} lg={13} xl={11} xxl={8}>
                <div className='block mb-0 mr-auto'>
                    <SelectEmpresas />
                </div>
            </Col>

            {exibirBotaoYoutube && (
                <Col className='ml-auto mr-2'>
                    <a href={youtube} target='_blank' rel='noreferrer'>
                        <Button>
                            <div className='inline-flex items-center'>
                                <YoutubeOutlined
                                    style={{ fontSize: 16, color: 'red', paddingRight: 4 }}
                                />
                                Vídeos
                            </div>
                        </Button>
                    </a>
                </Col>
            )}

            <Col
                xs={4}
                sm={1}
                md={2}
                lg={2}
                xl={2}
                xxl={1}
                className='flex flex-row-reverse'
                style={{
                    marginLeft: exibirBotaoYoutube ? 0 : 'auto',
                }}
            >
                <UserMenu />
            </Col>
        </Row>
    );
}
