import dayjs from 'dayjs';
import type { ApiEmpresa } from 'features/empresa/types/ApiEmpresa';
import { ApiEmpresaSituacao } from 'features/empresa/types/ApiEmpresaSituacao';
import { useLoginStore } from 'features/login/store';
import { useMemo } from 'react';
import { DATE_FORMAT } from 'std/const/date';

export function useEmpresaBloqueada(empresa?: Optional<ApiEmpresa>): {
    bloqueado: boolean;
    empresaSituacao: Optional<ApiEmpresaSituacao>;
    empresaDataBloqueio: Optional<string>;
} {
    const empresaStore = useLoginStore((s) => s.getEmpresa());
    const emp = empresa || empresaStore;
    const emp_situacao = emp?.emp_situacao;
    const emp_data_bloqueio = emp?.emp_data_bloqueio;

    const bloqueado = useMemo(() => {
        if (emp_situacao === ApiEmpresaSituacao.Bloqueada) {
            return true;
        }

        const now = dayjs();
        const dataBloqueio = dayjs(emp_data_bloqueio, DATE_FORMAT);

        if (emp_situacao === 2 && now.diff(dataBloqueio) > 0) {
            return true;
        }

        return false;
    }, [emp_situacao, emp_data_bloqueio]);

    return {
        bloqueado,
        empresaSituacao: emp_situacao,
        empresaDataBloqueio: emp_data_bloqueio,
    };
}
