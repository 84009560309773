import { notification } from 'antd';
import { apiFinanceiroContaSmartPosGet } from 'features/financeiro-conta/api/apiFinanceiroContaSmartPosGet';
import { apiFintechContaConsultar } from 'features/financeiro-conta/api/apiFintechContaConsultar';
import {
    type ApiFormaPagamento,
    ApiFormaPagamentoClassificacao,
} from 'features/forma-pagamento/api/apiFormaPagamentoConsultar';
import type { ApiProduto } from 'features/produto/types/ApiProduto';
import { getRegrasProduto } from 'features/produto/util';
import { nanoid } from 'nanoid';
import { roundAbnt } from 'std/math';
import { type AsyncResult, err, ok, safeTryAsync } from 'std/rusty/result';
import { TipoDesconto } from 'std/types/enum';
import type { PdvVendaFormaPagamento, PdvVendaItem } from './types/PdvVenda';

/** Número de casas decimais para ser usada na quantidade, valor unitário, desconto... Total deve ser 2.*/
export const PRECISION_PDV = 6;

export function calcularTotalProduto(item: PdvVendaItem): number {
    const vei_desconto_valor = item.vei_desconto_valor || 0;
    const vei_desconto_tipo = item.vei_desconto_tipo || TipoDesconto.VALOR_MOEDA;
    const vei_valor_unitario = item.vei_valor_unitario || 0;
    const vei_quantidade = item.vei_quantidade || 0;

    const itemValorTotalSemDesconto = vei_valor_unitario * vei_quantidade;
    let descontoValor = vei_desconto_valor;

    if (vei_desconto_tipo === TipoDesconto.PORCENTAGEM) {
        const itemValorDescontoAux = vei_desconto_valor > 100 ? 100 : vei_desconto_valor;
        descontoValor = (itemValorDescontoAux / 100) * itemValorTotalSemDesconto;
    }

    return roundAbnt(itemValorTotalSemDesconto - descontoValor);
}

export function produtoToPdvVendaItem({
    produto,
    vendaIdpk,
    quantidade,
    valorUnitario,
    descontoValor,
    descontoTipo,
}: {
    produto: ApiProduto;
    vendaIdpk: number;
    quantidade: number;
    valorUnitario: number;
    descontoValor: number;
    descontoTipo: '' | '$' | '%';
}): PdvVendaItem {
    return {
        key: nanoid(),
        regrasProduto: getRegrasProduto(produto),
        vei_venda_idpk: vendaIdpk,
        vei_quantidade: quantidade,
        vei_custo_medio: produto.pro_custo_medio,
        vei_valor_custo: produto.pro_preco_custo,
        vei_valor_unitario: valorUnitario,
        vei_valor_total: roundAbnt(quantidade * valorUnitario - descontoValor),
        vei_peso_bruto: produto.pro_peso_bruto,
        vei_peso_liquido: produto.pro_peso_liquido,
        vei_produto_idpk: produto.pro_idpk,
        vei_produto_nome: produto.pro_descricao,
        vei_produto_unidade: produto.produto_unidade?.pru_sigla,
        vei_produto_codigo_interno: produto.pro_codigo_interno,
        vei_produto_sku: produto.pro_sku,
        vei_regra_fiscal_idpk: produto.pro_produto_regra_fiscal_idpk,
        vei_desconto_valor: descontoValor,
        vei_desconto_tipo: descontoTipo,
        vei_volume_qtde: produto.pro_volume_qtde || 0,
    };
}

export function formaPagamentoToVendaFormaPagamento(
    formaPagamento: ApiFormaPagamento,
    vendaIdpk: number,
    valor: number,
): PdvVendaFormaPagamento {
    const isPrePago = [
        ApiFormaPagamentoClassificacao.CartaoCredito,
        ApiFormaPagamentoClassificacao.CartaoDebito,
        ApiFormaPagamentoClassificacao.Pix,
        ApiFormaPagamentoClassificacao.ValeAlimentacao,
    ].includes(formaPagamento.fop_classificacao);

    const vendaFormaPagamento: PdvVendaFormaPagamento = {
        key: nanoid(),
        vfp_venda_idpk: vendaIdpk,
        vfp_tipo: isPrePago ? 2 : 1,
        vfp_classificacao: formaPagamento.fop_classificacao,
        vfp_fixar_parcelas: 2,
        vfp_forma_pagamento_idpk: formaPagamento.fop_idpk,
        vfp_forma_pagamento_descricao: formaPagamento.fop_descricao,
        vfp_forma_pagamento_parcelas: 0,
        vfp_forma_pagamento_valor: valor,
        vfp_financeiro_conta_idpk: formaPagamento.fop_financeiro_conta_idpk,
    };

    if (formaPagamento.fop_numero_maximo_parcelas > 0) {
        vendaFormaPagamento.parcelas = [
            {
                vpp_parcela: 1,
                vpp_valor: valor,
            },
        ];
    }

    return vendaFormaPagamento;
}

export async function verificarFormaPagamentoCartaoPix(
    classificacao: ApiFormaPagamentoClassificacao,
): AsyncResult<null, string> {
    if (classificacao === ApiFormaPagamentoClassificacao.Pix) {
        const result = await safeTryAsync(apiFintechContaConsultar);

        if (result.isErr()) {
            return err('Falha ao consultar conta digital');
        }

        if (!result.value) {
            return err('Conta digital não encontrada');
        }
    }

    if (isCartao(classificacao)) {
        const result = await safeTryAsync(apiFinanceiroContaSmartPosGet);

        if (result.isErr()) {
            return err('Falha ao consultar maquininhas');
        }

        if (result.value.length === 0) {
            return err('Nenhuma maquininha encontrada');
        }
    }

    return ok(null);
}

export function isCartao(classificacao: ApiFormaPagamentoClassificacao): boolean {
    return (
        classificacao === ApiFormaPagamentoClassificacao.CartaoCredito ||
        classificacao === ApiFormaPagamentoClassificacao.CartaoDebito ||
        classificacao === ApiFormaPagamentoClassificacao.ValeAlimentacao
    );
}

export function vendaNaoLiberada(): void {
    notification.warning({
        message: 'Venda não liberada!',
        description: (
            <div>
                <span>
                    O produto não pode ser liberado para a venda pois não tem em estoque. Opções:
                </span>
                <ul style={{ padding: '0px' }}>
                    <li style={{ margin: '8px 0px' }}>
                        <b>Lançar entrada de estoque.</b>
                    </li>
                    <li style={{ margin: '8px 0px' }}>
                        <b>Alterar o produto e permitir estoque negativo.</b>
                    </li>
                    <li style={{ margin: '8px 0px' }}>
                        <b>Alterar o produto e remover o controle de estoque.</b>
                    </li>
                </ul>
            </div>
        ),
        placement: 'topRight',
        duration: 6,
    });
}
