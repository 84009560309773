import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import { Avatar, Dropdown, type MenuProps, Modal, Space, Spin } from 'antd';
import { useEmpresaQuery } from 'features/empresa/hooks/useEmpresaQuery';
import { apiLoginLogof } from 'features/login/api/apiLoginLogof';
import { useLoginStore } from 'features/login/store';
import { userHasPermission } from 'features/login/util';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { useMemo } from 'react';
import styles from './style.module.scss';

export function UserMenu() {
    const { empresa } = useEmpresaQuery();
    const navigate = useNavigate();

    const usuarioEmpresa = useLoginStore((s) => s.getUsuarioEmpresa());
    const usu_nome = useLoginStore((s) => s.login?.usu_nome);
    const usu_email = useLoginStore((s) => s.login?.usu_email);
    const usu_imagem_perfil = useLoginStore((s) => s.login?.usu_imagem_perfil);

    const mutationLogout = useMutation({
        mutationFn: apiLoginLogof,
    });

    const hasSupportInfo: boolean = useMemo(() => {
        const representante = empresa?.representante;

        return Boolean(
            representante?.rep_suporte_email ||
                representante?.rep_suporte_telefone ||
                representante?.rep_suporte_link_acesso_remoto ||
                representante?.rep_suporte_whatsapp,
        );
    }, [empresa?.representante]);

    const logout = async (): Promise<void> => {
        await mutationLogout.mutateAsync();
        navigate({ to: '/' });
    };

    const getItems = (): MenuProps => {
        const menu: MenuProps = {
            items: [
                {
                    label: (
                        <>
                            <strong>{usu_nome}</strong>
                            <div>
                                <strong>{'Função: '}</strong>
                                {usuarioEmpresa?.usuario_perfil_tipo || '—'}
                            </div>
                            <div>
                                <strong>{'Email: '}</strong>
                                {usu_email}
                            </div>
                        </>
                    ),
                    key: '1',
                },
                {
                    type: 'divider',
                },
                {
                    label: (
                        <Link to='/a/configuracao/usuario'>
                            <i className='mr-2 fe fe-user' />
                            Meu Perfil de Usuário
                        </Link>
                    ),
                    key: '2',
                },
            ],
        };

        if (userHasPermission('upp_configuracao_configuracao', TipoPermissao.Completo)) {
            menu.items?.push({
                label: (
                    <Link to='/a/configuracao/empresa' search={{ tab: 'negocio' }}>
                        <i className={`${styles.icon} fe fe-settings`} />
                        Configurações
                    </Link>
                ),
                key: '4',
            });
        }

        menu.items?.push(
            { type: 'divider' },
            {
                label: (
                    <Link to='/a/suporte/instrucoes-de-uso'>
                        <i className={`${styles.icon} fe fe-book`} />
                        Instruções de uso
                    </Link>
                ),
                key: '5',
            },
        );

        if (hasSupportInfo) {
            menu.items?.push(
                { type: 'divider' },
                {
                    label: (
                        <Link to='/a/suporte'>
                            <i className='mr-2 fe fe-message-circle' />
                            Suporte
                        </Link>
                    ),
                    key: '6',
                },
            );
        }

        menu.items?.push(
            {
                type: 'divider',
            },
            {
                label: (
                    <>
                        <i className='mr-2 fe fe-log-out' />
                        Logout
                    </>
                ),
                key: '7',
                onClick: logout,
            },
        );

        return menu;
    };

    return (
        <>
            <Dropdown menu={getItems()} trigger={['click']}>
                <div className={styles.dropdown}>
                    <Avatar
                        icon={<UserOutlined />}
                        className={styles.avatar}
                        shape='square'
                        size='large'
                        src={
                            typeof usu_imagem_perfil === 'string' && usu_imagem_perfil.length > 0
                                ? usu_imagem_perfil
                                : undefined
                        }
                    />
                </div>
            </Dropdown>

            <Modal
                open={mutationLogout.isPending}
                footer={null}
                width={200}
                closable={false}
                styles={{ body: { display: 'flex', justifyContent: 'center' } }}
            >
                <Space direction='vertical' align='center'>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />} />
                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>Saindo...</span>
                </Space>
            </Modal>
        </>
    );
}
