import { useLoginStore } from 'features/login/store';
import { canShowExtratoTemporario } from 'features/login/util';
import { userHasPermission } from 'features/login/util';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { useMemo } from 'react';
import { isSandbox } from 'std/url';
import type { MenuData } from './types';

export function useGetMenuData(): MenuData[] {
    const isFintech = useLoginStore((s) => s.isFintech());
    const empresaIdpk = useLoginStore((s) => s.empresaIdpk);

    // biome-ignore lint/correctness/useExhaustiveDependencies: quando a empresa mudar, recalcular o  menu
    const data: MenuData[] = useMemo(
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: é grande mesmo
        () => [
            {
                category: true,
                title: 'Administrativo',
                hide: isFintech,
            },
            {
                title: 'Painel Gerencial',
                key: 'painel-gerencial',
                icon: 'fe fe-home',
                url: '/a/painel-gerencial',
                hide: isFintech,
            },
            {
                category: true,
                title: 'Movimentos',
                hide: isFintech,
            },
            {
                title: 'Vendas',
                key: 'pedidos-vendas-orcamentos',
                icon: 'fe fe-shopping-cart',
                hide:
                    isFintech ||
                    !(
                        userHasPermission('upp_venda_venda', TipoPermissao.Parcial) ||
                        userHasPermission('upp_venda_venda_externa', TipoPermissao.Parcial) ||
                        userHasPermission('upp_fiscal_nfce', TipoPermissao.Completo) ||
                        userHasPermission('upp_venda_romaneio', TipoPermissao.Completo)
                    ),
                children: [
                    {
                        title: 'Bonificação',
                        key: 'bonificação',
                        url: '/a/venda/$tipo',
                        params: { tipo: 'bonificação' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_venda', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Pedidos',
                        key: 'pedido',
                        url: '/a/venda/$tipo',
                        params: { tipo: 'pedido' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_venda', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Orçamentos',
                        key: 'orçamento',
                        url: '/a/venda/$tipo',
                        params: { tipo: 'orçamento' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_venda', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Vendas',
                        key: 'venda',
                        url: '/a/venda/$tipo',
                        params: { tipo: 'venda' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_venda', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Vendas Externas',
                        key: 'vendas-externas',
                        url: '/a/venda-externa',
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_venda_externa', TipoPermissao.Parcial),
                    },
                    {
                        title: 'PDV - Frente de Caixa',
                        key: 'pdv',
                        url: '/a/pdv',
                        hide:
                            !isSandbox() ||
                            isFintech ||
                            !userHasPermission('upp_fiscal_nfce', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Expedição - Romaneio',
                        key: 'romaneio',
                        url: '/a/romaneio',
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_romaneio', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Comissões',
                        key: 'comissoes',
                        url: '/a/comissao',
                        hide:
                            isFintech ||
                            !userHasPermission('upp_venda_comissao', TipoPermissao.Parcial),
                    },
                ],
            },
            {
                title: 'Fiscal',
                key: 'fiscal',
                icon: 'fe fe-file',
                hide:
                    isFintech ||
                    !(
                        userHasPermission('upp_fiscal_nfe', TipoPermissao.Parcial) ||
                        userHasPermission('upp_fiscal_nfce', TipoPermissao.Parcial) ||
                        userHasPermission('upp_fiscal_mdfe', TipoPermissao.Completo) ||
                        userHasPermission('upp_fiscal_buscador_notas', TipoPermissao.Completo) ||
                        userHasPermission('upp_fiscal_regras', TipoPermissao.Completo)
                    ),
                children: [
                    {
                        title: 'Notas Fiscais (NF-e)',
                        key: 'nota-fiscal',
                        url: '/a/nota-fiscal',
                        hide: !userHasPermission('upp_fiscal_nfe', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Cupons Fiscais (NFC-e)',
                        key: 'cupom-fiscal',
                        url: '/a/cupom-fiscal',
                        hide: !userHasPermission('upp_fiscal_nfce', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Manifestos de Carga (MDF-e)',
                        key: 'manifestos-carga',
                        url: '/a/manifesto-carga',
                        hide:
                            isFintech ||
                            !userHasPermission('upp_fiscal_mdfe', TipoPermissao.Completo),
                    },
                    {
                        title: 'Buscador de Notas',
                        key: 'manifestador',
                        url: '/a/compra',
                        search: { tab: 'buscador-notas' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_fiscal_buscador_notas', TipoPermissao.Completo),
                    },
                    {
                        title: 'Grupos de regras fiscais',
                        key: 'regra-fiscal',
                        url: '/a/regra-fiscal',
                        hide:
                            isFintech ||
                            !userHasPermission('upp_fiscal_regras', TipoPermissao.Completo),
                    },
                ],
            },
            {
                title: 'Financeiro',
                key: 'financeiro',
                icon: 'fe fe-dollar-sign',
                hide: !(
                    (canShowExtratoTemporario([2, 3]) &&
                        userHasPermission('upp_financeiro_movimentacao', TipoPermissao.Completo)) ||
                    (userHasPermission('upp_fintech_administrativo', TipoPermissao.Completo) &&
                        canShowExtratoTemporario([1, 3])) ||
                    (userHasPermission('upp_fintech_administrativo', TipoPermissao.Completo) &&
                        canShowExtratoTemporario([1, 3])) ||
                    userHasPermission('upp_financeiro_movimentacao', TipoPermissao.Parcial) ||
                    userHasPermission('upp_financeiro_conta_receber', TipoPermissao.Parcial) ||
                    userHasPermission('upp_financeiro_conta_pagar', TipoPermissao.Completo) ||
                    userHasPermission('upp_financeiro_pix', TipoPermissao.Completo) ||
                    userHasPermission('upp_financeiro_boleto', TipoPermissao.Completo) ||
                    userHasPermission('upp_fintech_administrativo', TipoPermissao.Completo)
                ),
                children: [
                    {
                        title: 'Movimentações',
                        key: 'movimentacoes',
                        url: '/a/movimentacoes',
                        hide: !(
                            canShowExtratoTemporario([2, 3]) &&
                            userHasPermission('upp_financeiro_movimentacao', TipoPermissao.Completo)
                        ),
                    },
                    {
                        title: 'Contas a Receber',
                        key: 'contas-receber',
                        url: '/a/contas-receber',
                        hide: !userHasPermission(
                            'upp_financeiro_conta_receber',
                            TipoPermissao.Completo,
                        ),
                    },
                    {
                        title: 'Contas a Pagar',
                        key: 'contas-pagar',
                        url: '/a/contas-pagar',
                        hide: !userHasPermission(
                            'upp_financeiro_conta_pagar',
                            TipoPermissao.Completo,
                        ),
                    },
                    {
                        title: 'Cobranças Cartão',
                        key: 'cobranca-cartao',
                        url: '/a/cobranca-cartao',
                        hide: !userHasPermission('upp_financeiro_cartao', TipoPermissao.Completo),
                    },
                    {
                        title: 'Cobranças PIX',
                        key: 'cobranca-pix',
                        url: '/a/cobranca-pix',
                        hide: !userHasPermission('upp_financeiro_pix', TipoPermissao.Completo),
                    },
                    {
                        title: 'Extrato PIX',
                        key: 'extrato-pix',
                        url: '/a/extrato-pix',
                        hide: !(
                            userHasPermission(
                                'upp_fintech_administrativo',
                                TipoPermissao.Completo,
                            ) &&
                            canShowExtratoTemporario([1, 3]) &&
                            userHasPermission('upp_financeiro_movimentacao', TipoPermissao.Completo)
                        ),
                    },
                    {
                        title: 'Cobranças Boleto',
                        key: 'cobranca-boleto',
                        url: '/a/cobranca-boleto',
                        hide: !userHasPermission('upp_financeiro_boleto', TipoPermissao.Completo),
                    },
                    {
                        title: 'Extrato Boleto',
                        key: 'extrato-boleto',
                        url: '/a/extrato-boleto',
                        hide: !(
                            userHasPermission(
                                'upp_fintech_administrativo',
                                TipoPermissao.Completo,
                            ) &&
                            canShowExtratoTemporario([1, 3]) &&
                            userHasPermission('upp_financeiro_movimentacao', TipoPermissao.Completo)
                        ),
                    },
                ],
            },
            {
                title: 'Compras',
                key: 'compra',
                icon: 'fe fe-package',
                hide: isFintech || !userHasPermission('upp_compra_compra', TipoPermissao.Completo),
                children: [
                    {
                        title: 'Compras',
                        key: 'compras',
                        url: '/a/compra',
                        search: { tab: 'compras' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_compra_compra', TipoPermissao.Completo),
                    },
                ],
            },
            {
                title: 'Estoque',
                key: 'estoque',
                icon: 'fe fe-box',
                hide:
                    isFintech || !userHasPermission('upp_estoque_estoque', TipoPermissao.Completo),
                children: [
                    {
                        title: 'Movimentações',
                        key: 'estoque-movimentacao',
                        url: '/a/estoque/movimentacao',
                        hide: isFintech,
                    },
                    {
                        title: 'Conferência',
                        key: 'estoque-conferencia',
                        url: '/a/estoque/conferencia',
                        hide: isFintech,
                    },
                ],
            },
            {
                category: true,
                title: 'Operacional',
                hide: isFintech,
            },
            {
                title: 'Cadastros',
                key: 'cadastros',
                icon: 'fe fe-menu',
                hide:
                    !(
                        userHasPermission('upp_cadastro_cliente', TipoPermissao.Parcial) ||
                        userHasPermission('upp_cadastro_fornecedor', TipoPermissao.Parcial)
                    ) &&
                    (isFintech ||
                        !userHasPermission(
                            'upp_cadastro_transportadora',
                            TipoPermissao.Completo,
                        )) &&
                    (isFintech ||
                        !userHasPermission('upp_cadastro_produto', TipoPermissao.Completo)),
                children: [
                    {
                        title: 'Clientes',
                        key: 'cadastro-clientes',
                        url: '/a/cadastro/cliente',
                        hide: !userHasPermission('upp_cadastro_cliente', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Fornecedores',
                        key: 'cadastro-fornecedores',
                        url: '/a/cadastro/fornecedor',
                        hide: !userHasPermission('upp_cadastro_fornecedor', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Transportadores',
                        key: 'cadastro-transportadores',
                        url: '/a/cadastro/transportador',
                        hide:
                            isFintech ||
                            !userHasPermission(
                                'upp_cadastro_transportadora',
                                TipoPermissao.Completo,
                            ),
                    },
                    {
                        title: 'Produtos',
                        key: 'cadastro-produtos',
                        url: '/a/cadastro/produto',
                        hide: !userHasPermission('upp_cadastro_produto', TipoPermissao.Completo),
                    },
                ],
            },
            {
                title: 'Notificações',
                key: 'notificacao',
                icon: 'fe fe-box',
                hide: !userHasPermission('upp_notificacao_notificacao', TipoPermissao.Completo),
                children: [
                    {
                        title: 'Listagem',
                        key: 'notificacoes-listagem',
                        url: '/a/notificacao/listagem',
                    },
                ],
            },
            {
                title: 'Relatórios',
                key: 'relatorios',
                icon: 'fe fe-printer',
                hide:
                    (isFintech ||
                        !userHasPermission('upp_relatorio_venda', TipoPermissao.Parcial)) &&
                    (isFintech ||
                        !userHasPermission('upp_relatorio_fiscal', TipoPermissao.Parcial)) &&
                    !userHasPermission('upp_relatorio_financeiro', TipoPermissao.Completo) &&
                    (isFintech ||
                        !userHasPermission('upp_relatorio_estoque', TipoPermissao.Completo)) &&
                    !userHasPermission('upp_relatorio_cadastro', TipoPermissao.Parcial),
                children: [
                    {
                        title: 'Relatórios de vendas',
                        key: 'relatorios-vendas',
                        url: '/a/relatorio/$tipo',
                        params: { tipo: 'venda' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_relatorio_venda', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Relatórios fiscais',
                        key: 'relatorios-fiscais',
                        url: '/a/relatorio/$tipo',
                        params: { tipo: 'fiscal' },
                        hide:
                            isFintech ||
                            !userHasPermission('upp_relatorio_fiscal', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Relatórios financeiros',
                        key: 'relatorios-financeiros',
                        url: '/a/relatorio/$tipo',
                        params: { tipo: 'financeiro' },

                        hide: !userHasPermission(
                            'upp_relatorio_financeiro',
                            TipoPermissao.Completo,
                        ),
                    },
                    {
                        title: 'Relatórios de estoque',
                        key: 'relatorios-estoque',
                        url: '/a/relatorio/$tipo',
                        params: { tipo: 'estoque' },

                        hide:
                            isFintech ||
                            !userHasPermission('upp_relatorio_estoque', TipoPermissao.Completo),
                    },
                    {
                        title: 'Relatórios dos cadastros',
                        key: 'relatorios-cadastro',
                        url: '/a/relatorio/$tipo',
                        params: { tipo: 'cadastro' },

                        hide: !userHasPermission('upp_relatorio_cadastro', TipoPermissao.Parcial),
                    },
                    {
                        title: 'Relatórios de compras',
                        key: 'relatorios-compra',
                        url: '/a/relatorio/$tipo',
                        params: { tipo: 'compra' },

                        hide:
                            isFintech ||
                            !userHasPermission('upp_relatorio_compra', TipoPermissao.Completo),
                    },
                ],
            },
        ],
        [isFintech, empresaIdpk],
    );

    return data;
}
