import { useRouterState } from '@tanstack/react-router';
import { Button, notification } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { useSistemaStore } from 'features/sistema/store';
import { useCallback, useEffect, useMemo } from 'react';

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

let eventoPwa: BeforeInstallPromptEvent | null = null;

export function AlertPWA() {
    const sistema = useSistemaStore((s) => s.sistema);
    const [api] = notification.useNotification();
    const { pathname } = useRouterState().location;
    const currentRoute = location.pathname.split('/')[1];

    const { sis_logotipo = 'resources/images/logo.svg', sis_nome_sistema = 'Mercúrio' } =
        sistema || {};

    const askPWA = useLayoutStore((s) => s.askPWA);

    const askPWAinstallation = useMemo(() => {
        if (pathname.startsWith('/publico')) {
            return false;
        }

        return askPWA;
    }, [pathname, askPWA]);

    const triggerRemoverMenuPwaAposInstalacao = useCallback((): void => {
        if (eventoPwa) {
            eventoPwa.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    api.success({
                        message: 'Instalando',
                        description:
                            'Nosso aplicativo está sendo instalado no seu dispositivo, por favor aguarde...',
                        placement: 'bottomLeft',
                    });
                }
            });
        }
    }, [api]);

    const showInstallPromotion = useCallback(() => {
        api.open({
            key: 'instalarPWA',
            message: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={sis_logotipo}
                        className='mr-2'
                        alt='Logo'
                        style={{ width: '60px', height: '60px' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ textAlign: 'center' }}>
                            Adicionar {sis_nome_sistema} aos meus Apps
                        </span>
                        <Button
                            type='primary'
                            onClick={() => {
                                if (eventoPwa) {
                                    eventoPwa.prompt();
                                    api.destroy('instalarPWA');
                                    triggerRemoverMenuPwaAposInstalacao();
                                }
                            }}
                        >
                            Instalar
                        </Button>
                        <Button
                            onClick={() => {
                                useLayoutStore.setState({ askPWA: false });
                                api.destroy('instalarPWA');
                            }}
                            type='link'
                        >
                            <span style={{ textDecoration: 'underline' }}>
                                Não solicitar novamente
                            </span>
                        </Button>
                    </div>
                </div>
            ),
            placement: 'bottomLeft',
            duration: 30,
        });
    }, [sis_logotipo, sis_nome_sistema, api, triggerRemoverMenuPwaAposInstalacao]);

    useEffect(() => {
        const beforeInstallPromptHandler = (e: BeforeInstallPromptEvent) => {
            // Impede que o mini-infobar apareça em mobile
            e.preventDefault();
            // Guarda evento para que possa ser disparado depois.
            eventoPwa = e;
            // Atualiza UI notifica usuário que pode instalar PWA
            if (askPWAinstallation) {
                showInstallPromotion();
            }
        };

        if (!(currentRoute && ['compartilhamento', 'pagamento'].includes(currentRoute))) {
            window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        };
    }, [askPWAinstallation, currentRoute, showInstallPromotion]);

    useEffect(() => {
        const appinstalledHandler = () => {
            // Limpar o deferredPrompt para que seja coletado
            eventoPwa = null;
            // Opcionalmente, enviar evento de analytics para indicar instalação com sucesso
            console.debug('PWA was already installed');
        };

        window.addEventListener('appinstalled', appinstalledHandler);

        return () => {
            window.removeEventListener('appinstalled', appinstalledHandler);
        };
    }, []);

    return <></>;
}
