import { notification } from 'antd';
import type { CardProperties } from 'components-old/merc/CardList';
import { useLoginStore } from 'features/login/store';
import { nanoid } from 'nanoid';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponseStatus } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'std/types/enum/endpoint';
import { pick } from 'std/util';
import type { VendaExterna, VendaExternaStatus } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            total_registros: 'S' | 'N';
            getTodosRegistros?: boolean;
            where?: string;
            status?: VendaExternaStatus;
            registro_inicial?: number;
            qtde_registros?: number;
            resetPagination?: boolean;
        },
        state: RootState,
    ): Promise<VendaExterna[] | 'erro'> {
        dispatch.vendasExternas.setState({
            get: startReduxFn(),
        });

        const { filterStatus, getTable } = state.vendasExternas;
        const { sortParams } = getTable;
        const { where, registro_inicial, qtde_registros, resetPagination } = payload;

        const status = payload.status || filterStatus;

        const params: BuildUrlParams = {
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : getTable.registroInitial,
            qtde_registros: qtde_registros || getTable.qtdRegistros,
            total_registros: payload.total_registros,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            tipo_consulta: 'R',
            orderby: sortParams.shouldSort
                ? `${sortParams.fieldName}${sortParams.orderDirection}`
                : 'vee_idpk desc',
            ...(status && { status }),
            ...(where && { where }),
        };

        const url = buildUrl(Endpoint.VendaExternaGet, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.vendasExternas.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...state.vendasExternas.getTable,
                    registroInitial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : getTable.registroInitial,
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    qtdRegistros: qtde_registros || getTable.qtdRegistros,
                    pagination: {
                        ...getTable.pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current:
                                    registro_inicial / (qtde_registros || getTable.qtdRegistros) +
                                    1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });

            return registros;
        } catch (error) {
            dispatch.vendasExternas.setState({
                get: endReduxFnError(error),
            });

            return 'erro';
        }
    },

    async getOne(payload: { vee_idpk: number }): Promise<VendaExterna | 'erro'> {
        dispatch.vendasExternas.setState({
            getOne: startReduxFn(),
        });

        const { vee_idpk } = payload;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            tipo_consulta: 'D',
        };

        const url = buildUrl(Endpoint.VendaExternaGet, params, vee_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const { registros } = response.data;

            dispatch.vendasExternas.setState({
                getOne: endReduxFnOk(registros[0]),
                selectedVendaExterna: registros[0],
            });

            return registros[0];
        } catch (error) {
            dispatch.vendasExternas.setState({
                getOne: endReduxFnError(error),
            });

            return 'erro';
        }
    },

    async totalizadores(_, state: RootState): Promise<ApiResponseStatus> {
        const { vendasExternas } = state;
        const { totalizadores, filterStatus } = vendasExternas;

        const cores = {
            Ativas: 'royalblue',
            Finalizadas: 'green',
            Todas: 'purple',
        };

        dispatch.vendasExternas.setState({
            totalizadores: startReduxFn(totalizadores.data),
        });

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...(filterStatus && { status: filterStatus }),
        };

        const url = buildUrl(Endpoint.VendaExternaValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const { registros } = response.data;

            const totais: CardProperties[] = registros.map((reg) => ({
                color: cores[reg.tipo],
                title: reg.tipo.toUpperCase(),
                number: reg.quantidade_total,
                value: reg.tipo,
            }));

            dispatch.vendasExternas.setState({
                totalizadores: endReduxFnOk(totais),
            });
            return 'sucesso';
        } catch (error) {
            dispatch.vendasExternas.setState({
                totalizadores: endReduxFnError(error),
            });

            return 'erro';
        }
    },

    async post(payload: VendaExterna): Promise<{ vee_idpk: number } | 'erro'> {
        dispatch.vendasExternas.setState({
            post: startReduxFn(null),
        });

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.VendaExternaPost, params);

        try {
            const response = await comTokenPost(url, payload);
            throwIfResponseIsErr(response);

            notification.success({
                message: 'Feito.',
                description: 'Venda Externa criada com sucesso.',
            });

            dispatch.vendasExternas.setState({
                post: endReduxFnOk(response.data),
                hasUnsavedDataTable: 0,
                hasUnsavedData: false,
            });

            return { vee_idpk: response.data.registros[0].vee_idpk };
        } catch (error) {
            notification.error({
                message: 'Não foi possível criar venda externa.',
                description: `${error}`,
            });

            dispatch.vendasExternas.setState({
                post: endReduxFnError(error),
            });

            return 'erro';
        }
    },

    async remove(payload: { vee_idpk: number }): Promise<ApiResponseStatus> {
        dispatch.vendasExternas.setState({
            remove: startReduxFn(null),
        });
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.VendaExternaRemove, params, payload.vee_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            notification.success({
                message: 'Feito.',
                description: 'Venda Externa removida com sucesso.',
            });

            dispatch.vendasExternas.setState({
                remove: endReduxFnOk(null),
            });

            dispatch.vendasExternas.get({ total_registros: 'S' });

            return 'sucesso';
        } catch (error) {
            dispatch.vendasExternas.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível remover esta venda externa',
                description: `${error}`,
            });

            return 'erro';
        }
    },

    async put(payload: Partial<VendaExterna>, state: RootState): Promise<ApiResponseStatus> {
        dispatch.vendasExternas.setState({
            put: startReduxFn(null),
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
        const { vee_idpk } = payload;

        if (!vee_idpk) {
            return 'erro';
        }

        const { selectedVendaExterna } = state.vendasExternas;

        const diffList = Object.entries(payload)
            .filter((x) => !Object.entries(selectedVendaExterna || {}).includes(x))
            .map((diff) => diff[0]);

        //@ts-ignore
        const filteredPayload = pick(payload, diffList);
        const url = buildUrl(Endpoint.VendaExternaPut, params, vee_idpk);

        try {
            const response = await comTokenPut(url, filteredPayload);
            throwIfResponseIsErr(response);

            dispatch.vendasExternas.setState({
                put: endReduxFnOk(null),
                hasUnsavedDataTable: 0,
                hasUnsavedData: false,
            });

            notification.success({
                message: 'Feito.',
                description: 'Venda Externa alterada com sucesso.',
            });

            dispatch.vendasExternas.getOne({ vee_idpk: vee_idpk });

            return 'sucesso';
        } catch (error) {
            dispatch.vendasExternas.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível alterar esta venda externa.',
                description: `${error}`,
            });

            return 'erro';
        }
    },

    async gerarNotaRetorno(
        { finalizar }: { finalizar: 'S' | 'N' },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        dispatch.vendasExternas.setState({
            gerarNotaRetorno: startReduxFn(null),
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk, finalizar };

        const vee_idpk = state.vendasExternas.selectedVendaExterna?.vee_idpk;

        if (!vee_idpk) {
            return 'erro';
        }

        const url = buildUrl(Endpoint.VendaExternaGerarNotaFiscalRetorno, params, vee_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendasExternas.setState({
                gerarNotaRetorno: endReduxFnOk(null),
            });

            notification.success({
                message: 'Feito.',
                description: 'Nota de retorno gerada com sucesso',
            });

            return 'sucesso';
        } catch (error) {
            dispatch.vendasExternas.setState({
                gerarNotaRetorno: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível gerar a nota de retorno.',
                description: `${error}`,
            });

            return 'erro';
        }
    },

    async atualizarStatus(
        payload: { vee_idpk: number; acao: 'reabrir' | 'finalizar' },
        // state: RootState
    ): Promise<ApiResponseStatus> {
        dispatch.vendasExternas.setState({
            atualizarStatus: startReduxFn(null),
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            [payload.acao]: 'S',
        };

        const url = buildUrl(Endpoint.VendaExternaAtualizarStatus, params, payload.vee_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendasExternas.setState({
                atualizarStatus: endReduxFnOk(null),
                hasNoVendaExternaAtivaComEstoque: false,
            });

            notification.success({
                message: 'Feito.',
                description: `Venda Externa ${
                    payload.acao === 'reabrir' ? 'reaberta' : 'finalizada'
                } com sucesso.`,
            });

            dispatch.vendasExternas.get({ total_registros: 'N' });
            dispatch.vendasExternas.totalizadores({});

            return 'sucesso';
        } catch (error) {
            dispatch.vendasExternas.setState({
                atualizarStatus: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível alterar esta venda externa.',
                description: `${error}`,
            });

            return 'erro';
        }
    },

    triggerFormReload(): void {
        dispatch.vendasExternas.setState({ triggerFormReload: nanoid(10) });
    },

    setHasUnsavedDataTable(b: number, state: RootState): void {
        // Soma quandos dados estão em abertos ainda na tabela. Se acima de 0, existem dados em aberto.
        dispatch.vendasExternas.setState({
            hasUnsavedDataTable: (state.vendasExternas.hasUnsavedDataTable || 0) + b,
        });
        return;
    },

    setHasUnsavedData(b: boolean, state: RootState): void {
        // controles para não ficar setando false em cima de false, ou true em cima de true
        if (b && !state.vendasExternas.hasUnsavedData) {
            dispatch.vendasExternas.setState({ hasUnsavedData: true });
            return;
        }

        if (!b && state.vendasExternas.hasUnsavedData) {
            dispatch.vendasExternas.setState({ hasUnsavedData: false });
            return;
        }
    },
});
