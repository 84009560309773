import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { Button, Card, Col, Input, Row, Typography, message } from 'antd';
import { ButtonDynamicTextColor } from 'components-old/merc/button/ButtonDynamicTextColor';
import type { TCatalogoProduto } from 'models/catalogo-produtos/types';
import { useStateControlCatalogo } from 'pages/catalogo-produtos/useStateControlCatalogo';
import { useCallback, useMemo, useState } from 'react';
import { numberToString } from 'std/format';
import { useScreenAliases } from 'std/hooks/useScreenAliases';
import { Show } from 'std/util/show';
import { ProdutoNaoEncontrado } from './ProdutoNaoEncontrado';
import style from './style.module.scss';

const { Title, Paragraph } = Typography;

const patternOnlyNumbers = /^[0-9]*$/;

export function ProductInfo({
    proIdpk,
}: {
    proIdpk: string | null | undefined;
}) {
    const { isSmallerThanTablet } = useScreenAliases();
    const navigate = useNavigate();
    const { catalogoProdutos, catalogoActions } = useStateControlCatalogo();

    const {
        getCatalogoData: { data },
        getCatalogoHeaderData,
        showPrice,
        primaryColor,
    } = catalogoProdutos;

    const produtos = data?.registros;

    const [produtoQuantity, setProdutoQuantity] = useState(1);
    const [productWasAddedToCart, setProductWasAddedToCart] = useState(false);

    const changeProdutoQuantity = (value: number | 'increase' | 'decrease'): void => {
        if (typeof value === 'number') {
            setProdutoQuantity(value);
            return;
        }

        if (value === 'increase') {
            setProdutoQuantity((prevData) => prevData + 1);
        } else {
            setProdutoQuantity((prevData) => prevData - 1);
        }
    };

    const produto = useMemo((): TCatalogoProduto | null => {
        if (!(proIdpk && produtos && produtos.length)) {
            return null;
        }

        return produtos.find((p) => p.pro_idpk.toString() === proIdpk) || null;
    }, [produtos, proIdpk]);

    const addProdutoToCart = useCallback(() => {
        if (!produto) {
            return;
        }
        setProductWasAddedToCart(true);
        message.success({
            content: 'Produto adicionado à sacola',
        });
        catalogoActions.addProdutoToCart({ produto, quantity: produtoQuantity });
    }, [catalogoActions, produto, produtoQuantity]);

    const slugRoute = getCatalogoHeaderData.data?.empresa.empresa_slug?.ems_slug || '';

    const openShoppingCart = (): void => {
        navigate({
            to: '/p/catalogo/$slug',
            params: { slug: slugRoute },
            search: { page: 'sacola' },
        });
    };

    const backToProdutoList = (): void => {
        navigate({
            to: '/p/catalogo/$slug',
            params: { slug: slugRoute },
        });
    };

    const fotoPath = useMemo((): string => {
        const fotoPlaceholder = '/resources/images/productPlaceholder.jpg';

        if (!produto?.produto_foto?.length) {
            return fotoPlaceholder;
        }

        const mainFoto = produto.produto_foto.find(
            (produtoFoto) => produtoFoto.prf_foto_principal === 1,
        );

        return mainFoto?.prf_foto_path || fotoPlaceholder;
    }, [produto]);

    if (!produto) {
        return <ProdutoNaoEncontrado />;
    }

    return (
        <>
            <div className={style['placeholder-box']} />

            <Row className={style['product-info-container']}>
                <Col
                    xs={24}
                    md={8}
                    lg={6}
                    key={produto.pro_idpk}
                    className={style['product-info-box']}
                    style={{ minHeight: 235 }}
                >
                    <Card
                        className='flex flex-col justify-between items-center pt-4'
                        cover={
                            <img
                                src={fotoPath}
                                alt='imagem-produto'
                                style={{
                                    width: '75%',
                                    aspectRatio: '1',
                                    margin: '10px auto',
                                }}
                            />
                        }
                        hoverable={true}
                    >
                        <Paragraph
                            className='text-center w-full'
                            style={{
                                fontSize: isSmallerThanTablet ? 12 : 14,
                                fontWeight: 600,
                            }}
                        >
                            {produto.descricao}
                        </Paragraph>

                        <Title
                            level={isSmallerThanTablet ? 4 : 2}
                            className='text-center font-bold my-0 py-0'
                        >
                            {showPrice
                                ? numberToString(produto.preco_venda, 'currency')
                                : 'Consulte'}
                        </Title>

                        <Row className='flex justify-center'>
                            <Button
                                onClick={() => {
                                    changeProdutoQuantity('decrease');
                                }}
                                ghost={true}
                                style={{ border: 0, color: 'black' }}
                            >
                                <MinusOutlined />
                            </Button>
                            <Input
                                style={{ width: 50, textAlign: 'center' }}
                                value={produtoQuantity}
                                onChange={(evt) => {
                                    const isNumber = patternOnlyNumbers.test(evt.target.value);

                                    if (isNumber) {
                                        changeProdutoQuantity(Number(evt.target.value));
                                    }
                                }}
                            />
                            <Button
                                onClick={() => {
                                    changeProdutoQuantity('increase');
                                }}
                                ghost={true}
                                style={{ border: 0, color: 'black' }}
                            >
                                <PlusOutlined />
                            </Button>
                        </Row>

                        <Col className='flex flex-col mt-3' style={{ gap: 5 }}>
                            <Show
                                when={productWasAddedToCart}
                                fallback={
                                    <ButtonDynamicTextColor
                                        onClick={addProdutoToCart}
                                        backgroundColor={primaryColor}
                                    >
                                        Adicionar à sacola
                                    </ButtonDynamicTextColor>
                                }
                            >
                                <ButtonDynamicTextColor
                                    onClick={backToProdutoList}
                                    backgroundColor={primaryColor}
                                >
                                    Continuar comprando
                                </ButtonDynamicTextColor>
                                <ButtonDynamicTextColor
                                    onClick={openShoppingCart}
                                    type='text'
                                    style={{
                                        border: `2px solid ${primaryColor}`,
                                        fontWeight: 'bold',
                                    }}
                                    backgroundColor={'#fff'}
                                >
                                    Abrir sacola de compras
                                </ButtonDynamicTextColor>
                            </Show>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
