import { createModel } from '@rematch/core';
import { TipoPessoa } from 'features/pessoa/types';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { FornecedoresState } from './types';

const initialState: FornecedoresState = {
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    put: initReduxFn,
    remove: initReduxFn,
    documentoExiste: initReduxFn,

    showModal: false,
    showModalMessageError: false,
    shouldSearch: false,
    formEditMode: undefined,
    filterNome: '',
    fornecedorSelecionado: null,
    tipoPessoa: TipoPessoa.Juridica,
    dataSearchCNPJ: undefined,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: () => '',
        showSizeChanger: false,
    },
    sortParams: {
        shouldSort: false,
        fieldName: '',
        orderDirection: 'asc',
    },
};

export const FornecedoresModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<FornecedoresState>(),
    effects,
});
