import { useRouterState } from '@tanstack/react-router';
import { Layout, Menu } from 'antd';
import classNames from 'classnames';
import { ButtonSandbox } from 'features/debug/components/ButtonSandbox';
import { useGetMenuData } from 'features/layout/components/Menu/useGetMenuData';
import { useLayoutStore } from 'features/layout/store';
import { useLoginStore } from 'features/login/store';
import { useSistemaStore } from 'features/sistema/store';
import type { MenuInfo as AntdMenuInfo } from 'rc-menu/lib/interface';
import { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { flattenItems } from '../utils';
import style from './style.module.scss';
import { useGenerateMenuItems } from './useGenerateMenuItems';

export function MenuLeft() {
    const routerState = useRouterState();
    const menuData = useGetMenuData();

    const isFintech = useLoginStore((s) => s.isFintech());
    const sistema = useSistemaStore((s) => s.sistema);
    const menuMobile = useLayoutStore((s) => s.menuMobile);
    const menuOpen = useLayoutStore((s) => s.menuOpen);
    const menuOpenKeys = useLayoutStore((s) => s.menuOpenKeys);
    const menuSelectedKey = useLayoutStore((s) => s.menuSelectedKey);

    const logoRedirect = isFintech ? '/movimentacoes' : '/painel-gerencial';

    useEffect(() => {
        const items = flattenItems(menuData, 'children');

        const selectedItem = items.find((item) => {
            if (item.search?.tab === 'buscador-notas') {
                return false;
            }

            const sub = item.url?.split('$')[0];

            if (sub && routerState.location.pathname.startsWith(sub)) {
                if (item.url?.includes('$') && item.params?.tipo) {
                    //@ts-ignore
                    return routerState.matches.at(-1)?.params.tipo === item.params.tipo;
                }

                return true;
            }

            return false;
        });

        useLayoutStore.setState({ menuSelectedKey: selectedItem?.key });
    }, [routerState, menuData]);

    const onCollapse = (collapsed: boolean): void => {
        useLayoutStore.setState((s) => ({ menuOpen: !s.menuOpen }));

        // com o menu colapsado, é necessário limpar o grupo aberto
        // caso contrario aparece o menu flutuante em telas mobile
        // sem necessidade e prejudicando a ux
        if (collapsed) {
            useLayoutStore.setState({ menuOpenKeys: [] });
        }
    };

    const onOpenChange = (keys: React.Key[]): void => {
        const keysStr = keys.map((key: React.Key) => String(key));
        useLayoutStore.setState({ menuOpenKeys: keysStr });
    };

    const handleClick = (e: AntdMenuInfo): void => {
        useLayoutStore.setState({ menuSelectedKey: e.key });
    };

    const menuSettings: Record<string, unknown> = menuMobile
        ? {
              width: 256,
              collapsible: false,
              collapsed: false,
              onCollapse,
          }
        : {
              width: 256,
              collapsible: true,
              collapsed: !menuOpen,
              onCollapse,
              breakpoint: 'lg',
          };

    return (
        <Layout.Sider
            {...menuSettings}
            className={classNames(`${style.menu} shadow-xl`, {
                white: true,
                unfixed: false,
            })}
        >
            <div
                className={style.menuOuter}
                style={{
                    width: menuOpen || menuMobile ? 256 : 80,
                    height: menuMobile ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
                }}
            >
                <div className={style.logoContainer}>
                    <a href={`a${logoRedirect}`}>
                        <div className={style.logo}>
                            <img
                                src={
                                    sistema?.sis_logotipo
                                        ? sistema.sis_logotipo
                                        : 'resources/images/logo.svg'
                                }
                                className='mr-2'
                                alt='Mercúrio WEB'
                            />

                            <div
                                style={{ color: sistema?.layout.sic_cor_sistema_nome }}
                                className={style.name}
                            >
                                {sistema?.sis_nome_sistema}
                            </div>

                            <div className={style.descr}>{isFintech ? 'FINTECH' : 'ERP'}</div>
                        </div>
                    </a>
                </div>

                <ButtonSandbox />

                <PerfectScrollbar>
                    <Menu
                        onClick={handleClick}
                        selectedKeys={[menuSelectedKey]}
                        openKeys={menuOpenKeys}
                        onOpenChange={onOpenChange}
                        mode='inline'
                        className={style.navigation}
                        inlineIndent={15}
                        items={useGenerateMenuItems()}
                    />
                </PerfectScrollbar>
            </div>
        </Layout.Sider>
    );
}
