import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type LayoutStore = {
    breadcrumbs: string[];

    menuOpen: boolean;
    menuMobile: boolean;
    menuOpenKeys: string[];
    menuSelectedKey: string;

    askPWA: boolean;
    newVersion?: string;
};

export const useLayoutStore = create<LayoutStore>()(
    devtools(
        persist(
            (_) => ({
                breadcrumbs: [],

                menuOpen: true,
                menuMobile: false,
                menuOpenKeys: [],
                menuSelectedKey: 'painel-gerencial',

                askPWA: true,
            }),
            {
                name: 'layout',
                partialize: (state) => ({
                    menuOpenKeys: state.menuOpenKeys,
                    menuSelectedKey: state.menuSelectedKey,
                }),
            },
        ),
        { name: 'layout' },
    ),
);
