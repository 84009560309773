import { CustomSpin } from 'components-old/merc/CustomSpin';
import { Helmet } from 'components-old/merc/Helmet';
import { useEffect } from 'react';

export function Splash() {
    useEffect(() => {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;

        if (favicon) {
            favicon.href = 'data:image/x-icon;';
        }
    }, []);

    return (
        <div>
            <Helmet title='Carregando' />

            <div style={{ textAlign: 'center' }}>
                <CustomSpin />
            </div>
        </div>
    );
}
