import { createFileRoute } from '@tanstack/react-router';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import { type ApiVendaTipo, apiVendaTipo } from 'features/venda/types';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/venda/$tipo')({
    beforeLoad: ({ params }) => {
        const { tipo } = params;

        validateRoute({
            permissao: 'upp_venda_venda',
            nivel: TipoPermissao.Parcial,
            podeExibirFintech: false,
            condicao: apiVendaTipo.includes(tipo as ApiVendaTipo),
        });
    },
});
