export enum TipoPermissao {
    Bloqueado = 0,
    Completo = 1,
    Parcial = 2,
}

export type Permissao = keyof Omit<
    ApiUsuarioTipoPermissao,
    'upp_idpk' | 'upp_empresa_idpk' | 'upp_usuario_tipo_idpk'
>;

export type ApiUsuarioTipoPermissao = {
    upp_idpk: number;
    upp_empresa_idpk: number;
    upp_usuario_tipo_idpk: number;
    upp_cadastro_cliente: TipoPermissao;
    upp_cadastro_produto: TipoPermissao;
    upp_cadastro_fornecedor: TipoPermissao;
    upp_cadastro_transportadora: TipoPermissao;
    upp_estoque_estoque: TipoPermissao;
    upp_venda_venda: TipoPermissao;
    upp_venda_romaneio: TipoPermissao;
    upp_venda_venda_externa: TipoPermissao;
    upp_venda_atendimento: TipoPermissao;
    upp_venda_comissao: TipoPermissao;
    upp_fiscal_nfe: TipoPermissao;
    upp_fiscal_nfce: TipoPermissao;
    upp_fiscal_mdfe: TipoPermissao;
    upp_fiscal_regras: TipoPermissao;
    upp_fiscal_buscador_notas: TipoPermissao;
    upp_compra_compra: TipoPermissao;
    upp_financeiro_movimentacao: TipoPermissao;
    upp_financeiro_conta_receber: TipoPermissao;
    upp_financeiro_conta_pagar: TipoPermissao;
    upp_financeiro_boleto: TipoPermissao;
    upp_financeiro_pix: TipoPermissao;
    upp_financeiro_cartao: TipoPermissao;
    upp_financeiro_estorno: TipoPermissao;
    upp_fintech_administrativo: TipoPermissao;
    upp_relatorio_venda: TipoPermissao;
    upp_relatorio_fiscal: TipoPermissao;
    upp_relatorio_financeiro: TipoPermissao;
    upp_relatorio_estoque: TipoPermissao;
    upp_relatorio_cadastro: TipoPermissao;
    upp_relatorio_compra: TipoPermissao;
    upp_configuracao_configuracao: TipoPermissao;
    upp_notificacao_notificacao: TipoPermissao;
};
