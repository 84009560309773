import { createRoot } from 'react-dom/client';
import { App } from './App/App';
import './globalStyle.scss';
import 'core-js/actual';
import 'core-js/proposals/promise-with-resolvers';
import { initBeforeReact } from 'initBeforeReact';

initBeforeReact();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<App />);
