import { Link } from '@tanstack/react-router';
import { useLayoutStore } from 'features/layout/store';
import type { RoutePath } from 'router/util';
import style from './style.module.scss';

export function MenuItemLink({
    url,
    params,
    search,
    title,
    icon,
}: {
    url: RoutePath;
    params?: Record<string, any>;
    search?: Record<string, any>;
    title: string;
    icon: string | undefined;
}) {
    const menuMobile = useLayoutStore((s) => s.menuMobile);

    return (
        <Link
            //@ts-ignore
            to={url}
            //@ts-ignore
            params={params}
            search={search}
            onClick={() => {
                if (menuMobile) {
                    setTimeout(() => {
                        useLayoutStore.setState({ menuOpen: false });
                    }, 200);
                }
            }}
        >
            <span className={style.title}>{title}</span>
            {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Link>
    );
}
