import { Link } from '@tanstack/react-router';
import { Helmet } from 'components-old/merc/Helmet';

export function Error404() {
    return (
        <div>
            <Helmet title='Página 404' />

            <div className='container pl-5 pr-5 pt-5 pb-5 mb-auto text-black text-4xl'>
                <div className='font-bold mb-3'>Página não encontrada</div>
                <div className='text-gray-500 text-2xl'>
                    Desculpe, a página solicitada não foi encontrada ou foi removida
                </div>
                <div className='font-bold text-7xl mb-1'>404</div>

                <Link to='/' className='btn btn-outline-primary w-full'>
                    Voltar
                </Link>
            </div>
        </div>
    );
}
