export function getVendaDateColumn(tipo: string | undefined): string {
    let coluna = 'ven_data';

    switch (tipo) {
        case 'venda': {
            coluna = 'ven_data_venda';
            break;
        }
        case 'pedido': {
            coluna = 'ven_data_pedido';
            break;
        }
        case 'orçamento': {
            coluna = 'ven_data_orcamento';
            break;
        }
        case 'bonificação': {
            // ven_data_bonificacao não existe. é ven_data_venda mesmo.
            coluna = 'ven_data_venda';
            break;
        }
    }

    return coluna;
}
