import { createFileRoute, redirect } from '@tanstack/react-router';
import type { ApiVendaTipo } from 'features/venda/types';
import { type FormEditMode, formEditMode } from 'std/types/enum';

export const Route = createFileRoute('/a/venda/venda-externa/$tipo/$mode/$idpk/')({
    beforeLoad({ params }) {
        const { tipo, mode } = params;

        if (!(tipo.includes(tipo as ApiVendaTipo) && formEditMode.includes(mode as FormEditMode))) {
            throw redirect({ to: '/' });
        }
    },
});
