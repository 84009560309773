import type { ApiProduto, ApiProdutoEstoque } from './types/ApiProduto';
import type { RegrasProduto } from './types/RegrasProduto';

// Quando for implementado o estoque compartilhado entre empresas tem que ser feito um find
export function getEstoqueDisponivel(produto_estoque: ApiProdutoEstoque[]): number {
    return produto_estoque[0]?.pre_estoque_disponivel || 0;
}

/** Verifica se o produto pode ser vendido com pelo menos 1 de quantidade */
export function produtoPodeSerVendido(
    {
        estoqueDisponivel,
        controlaEstoque,
        permiteEstoqueNegativo,
        permiteVendaFracionada,
    }: RegrasProduto,
    estoque = 1,
): boolean {
    // Se o produto não controla estoque então ele pode ser vendido
    if (!controlaEstoque) {
        return true;
    }

    // Se não pode venda fracionada e a quantidade é decimal então retorna false
    if (!(permiteVendaFracionada || Number.isInteger(estoque))) {
        return false;
    }

    // Se o produto controla o estoque e permite ficar com o estoque negativo então ele também pode ser vendido
    if (controlaEstoque && permiteEstoqueNegativo) {
        return true;
    }

    // Se o produto controla o estoque e não permite ficar com o estoque negativo
    // então valida se tem estoque suficiente para vender
    if (controlaEstoque && !permiteEstoqueNegativo && estoque <= estoqueDisponivel) {
        return true;
    }

    return false;
}

export function getRegrasProduto(
    produto: Pick<
        ApiProduto,
        | 'produto_estoque'
        | 'pro_preco_minimo'
        | 'pro_controlar_estoque'
        | 'pro_permite_mudar_preco'
        | 'pro_permite_estoque_negativo'
        | 'pro_permite_venda_fracionada'
    >,
): RegrasProduto {
    return {
        estoqueDisponivel: produto.produto_estoque?.[0]?.pre_estoque_disponivel || 0,
        precoMinimo: produto.pro_preco_minimo || 0,
        controlaEstoque: produto.pro_controlar_estoque === 1,
        permiteMudarPreco: produto.pro_permite_mudar_preco === 1,
        permiteEstoqueNegativo: produto.pro_permite_estoque_negativo === 1,
        permiteVendaFracionada: produto.pro_permite_venda_fracionada === 1,
    };
}
