import type { ApiSistema } from 'features/sistema/types';
import { newRequest } from 'std/api/newRequest';
import { isLocalHost } from 'std/url';

const url = isLocalHost()
    ? // ? 'sandbox.resultipay.com.br'
      // ? 'sandbox.app.pixapay.com.br'
      'sandbox.app.mercurioapp.com.br'
    : window.location.hostname;

export function apiSistemaPost(): Promise<ApiSistema[]> {
    return newRequest({
        url: 'Sistema/Sistema',
        type: 'post',
        body: { url },
        token: false,
    });
}
