import { Status } from 'std/types/enum';
import type { ApiResponse } from './types';

/** @deprecated Verifica se a requisição para o back retornou ok */
export function isResponseOk(response: ApiResponse): boolean {
    if (!response?.data?.status) {
        return false;
    }

    // Verifica se a parte "de fora" da requisição está OK
    const outsideOk = response.status === 200 && response.data.status === Status.Ok;

    // Verifica se tem um array de registros e se algum deles retornou erro
    let insideOk = true;

    if (Array.isArray(response.data.registros)) {
        for (const registro of response.data.registros) {
            if ('status' in registro) {
                if (registro.status === 'erro') {
                    insideOk = false;
                    break;
                }
            }
        }
    }

    return outsideOk && insideOk;
}

/** @deprecated Verifica se a requisição para o back retornou erro */
export function isResponseErr(response: ApiResponse): boolean {
    return !isResponseOk(response);
}

/** @deprecated Se a requisição para o back retornou erro gera uma exceção */
export function throwIfResponseIsErr(response: ApiResponse): void {
    if (isResponseErr(response)) {
        throw new Error(response.data?.mensagem || '');
    }
}
