import { ConfigProvider, InputNumber as InputNumberAntd } from 'antd';
import { type FocusEvent, type KeyboardEvent, useState } from 'react';
import { numberToString, stringToNumber } from 'std/format';
import { truncateDecimal } from 'std/math';

/** Input númerico. Serve para números inteiros e decimais */
export function InputNumber({
    id,
    addonAfter,
    addonBefore,
    allowNegative = false,
    autoFocus,
    colorText,
    placeholder,
    decimalScale = 0,
    disabled,
    min,
    max,
    readOnly,
    style = { width: '100%' },
    className,
    value,
    onPressEnter,
    onChange,
    onBlur,
    onKeyDown,
}: {
    /** Id para buscar o input*/
    id?: string;
    /** Símbolo que aparece depois no input */
    addonAfter?: string;
    /** Símbolo que aparece antes no input */
    addonBefore?: string;
    /** Se permite números negativos. Default: false */
    allowNegative?: boolean;
    autoFocus?: boolean;
    /** Cor do texto. Por style não funciona. Precisa ser injetado no antd */
    colorText?: string;
    placeholder?: string;
    /** Quantidade de casas decimais após a virgula. Default: 0 */
    decimalScale?: number;
    disabled?: boolean;
    min?: number;
    max?: number;
    readOnly?: boolean;
    className?: string;
    style?: React.CSSProperties;
    value?: number | null;
    onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onChange?: (newValue: number | null) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}) {
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = (): void => {
        setIsFocused(true);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
        setIsFocused(false);

        if (onBlur) {
            onBlur(e);
        }
    };

    const fixValue = (newValue: number | null | undefined): number | null => {
        if (typeof newValue !== 'number') {
            return null;
        }

        // Se não permite valor negativo e o valor é menor que 0 então retorna o valor positivo
        if (!allowNegative && newValue < 0) {
            return Math.abs(newValue);
        }

        // Se não pode casas decimais trunca o valor
        if (decimalScale === 0) {
            return Math.trunc(newValue);
        }

        // Força o número a ter as casas decimais informadas
        return truncateDecimal(newValue, decimalScale);
    };

    const handleOnChange = (newValue: number | null): void => {
        if (onChange) {
            onChange(fixValue(newValue));
        }
    };

    const placeholderInner = placeholder
        ? placeholder
        : decimalScale === 0
          ? '0'
          : numberToString(0, 'decimal', decimalScale);

    const innerValue = fixValue(value);

    const formatter = (value: Optional<string>): string => {
        if (!value) {
            return '';
        }

        // Se o input está com foco então retorna o valor sem formatar
        if (isFocused) {
            return value.replace('.', ',');
        }

        const num = Number(value);

        if (Number.isNaN(num)) {
            return '';
        }

        return numberToString(num, 'decimal', decimalScale);
    };

    const parser = (displayValue: Optional<string>): number | null => {
        if (typeof displayValue !== 'string' || displayValue === '') {
            return null;
        }

        if (displayValue === '-') {
            // @ts-ignore Para lidar com números negativos
            return displayValue;
        }

        return stringToNumber(displayValue);
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (onKeyDown) {
            onKeyDown(e);
        }

        // Se não deixa negativo não deixa digitar -
        if (!allowNegative && e.key === '-') {
            e.preventDefault();
        }

        // Se for apenas números inteiros não permite . ou ,
        if (decimalScale === 0 && (e.key === '.' || e.key === ',')) {
            e.preventDefault();
        }

        // Verifica se a tecla pressionada é valida
        if (!allowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    InputNumber: {
                        colorText,
                    },
                },
            }}
        >
            <InputNumberAntd
                id={id}
                addonAfter={addonAfter}
                addonBefore={addonBefore}
                autoFocus={autoFocus}
                className={className}
                controls={false}
                decimalSeparator=','
                disabled={disabled}
                //@ts-ignore - formatter retornar uma string não um number
                formatter={formatter}
                min={min}
                max={max}
                //@ts-ignore - parser não aceita null
                parser={parser}
                placeholder={placeholderInner}
                readOnly={readOnly}
                style={style}
                value={innerValue}
                onBlur={handleBlur}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onFocus={onFocus}
                onPressEnter={onPressEnter}
            />
        </ConfigProvider>
    );
}

const allowedKeys = [
    'a', // Pra poder dar CTRL + a para selecionar tudo
    ',', // Decimais
    '.', // Decimais
    '-',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'Delete',
    'Control',
    'Home',
    'End',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
];
