import type { ApiAcao } from 'features/acao/types';
import type { ApiVendaFormaPagamento } from 'features/venda/venda-forma-pagamento/types';
import type { ApiVendaItem } from './venda-item/types';

export const apiVendaTipo = ['pedido', 'orçamento', 'venda', 'bonificação'] as const;
export type ApiVendaTipo = (typeof apiVendaTipo)[number];

export const apiVendaStatus = ['Finalizada', 'Cancelada', 'Pendente', 'Recusado', 'Cancelado'];
export type ApiVendaStatus = (typeof apiVendaStatus)[number];

export type ApiVenda = Partial<{
    acoes: ApiAcao[];
    itens: ApiVendaItem[];
    sistema_integrado: unknown;
    formas_de_pagamento: ApiVendaFormaPagamento[];
    venda_nota_fiscal: ApiVendaNotaFiscal[];
}> & {
    ven_idpk: number;
    ven_empresa_idpk: number;
    ven_numero: string;
    ven_tipo: ApiVendaTipo;
    ven_status: ApiVendaStatus;
    ven_data: string;
    ven_valor_total: number;
    ven_peso_bruto: number;
    ven_peso_liquido: number;
} & Partial<{
        ven_financeiro_categoria_idpk: number;
        ven_tabela_preco_idpk: number;
        ven_hora: string;

        ven_cliente_idpk: number;
        ven_cliente_nome: string;
        ven_cliente_apelido: string;
        ven_cliente_documento: string;
        ven_cliente_cep: string;
        ven_cliente_logradouro: string;
        ven_cliente_logradouro_numero: string;
        ven_cliente_complemento: string;
        ven_cliente_bairro: string;
        ven_cliente_cidade_idpk: number;
        ven_cliente_cidade: string;
        ven_cliente_uf: string;
        ven_cliente_pais_codigo: number;
        ven_cliente_responsavel: string;
        ven_cliente_fone: string;
        ven_cliente_email: string;

        ven_vendedor_idpk: number;
        ven_vendedor_nome: string;
        ven_vendedor_sequencial: number;

        ven_produtos_valor: number;
        ven_desconto_valor: number;
        ven_desconto_tipo: '%' | '$' | '';

        ven_transportador_idpk: number;
        ven_transportador_nome: string;
        ven_transportador_documento: string;

        ven_volume_qtde: number;

        /**
            - 0: Contratação do Frete por conta do Remetente (CIF)
            - 1: Contratação do Frete por conta do Destinatário (FOB)
            - 2: Contratação do Frete por conta de Terceiros
            - 3: Transporte Próprio por conta do Remetente
            - 4: Transporte Próprio por conta do Destinatário
            - 9: Sem Ocorrência de Transporte
         */
        ven_frete_responsavel: number;

        ven_total_seguro: number;
        ven_total_despesas_acessorias: number;
        ven_total_frete: number;

        ven_observacao_interna: string;
        ven_observacao_cliente: string;

        /**
            Informativo de onde originou este registro:
            - 1: Venda
            - 2: NFe (Nota fiscal)
            - 3: NFCe
            - 4: Compra
            - 5: Conta a receber
            - 6: Conta a pagar
            - 7: Boleto
            - 8: Pix
        */
        ven_origem: number;
        ven_origem_id: number;

        ven_validade_ate: string;
        ven_previsao_faturamento: string;
        ven_previsao_entrega_de: string;
        ven_previsao_entrega_ate: string;

        ven_link_compartilhamento: string;
        ven_nota_gerada: 'S' | 'N';

        /** Campo que indica se este registro já faz parte de um romaneio ou não:
            - 0: Não
            - 1: Sim 
        */
        ven_romaneio: 0 | 1;

        ven_usuario_idpk: number;
        ven_usuario: string;

        ven_data_orcamento: string;
        ven_data_pedido: string;
        ven_data_venda: string;

        ven_total_imp_icmsst_valor: number;
        ven_total_imp_fcpst_valor: number;
        ven_total_imp_ipi_valor: number;
        ven_total_imp_ii_valor: number;

        ven_venda_externa_idpk: number;
        ven_marcadores: string;
        ven_ultima_alteracao: string;
    }>;

export type ApiVendaNotaFiscal = {
    vnf_idpk: number;
    vnf_venda_idpk: number;
    vnf_nfce_idpk: number | null;
    vnf_nota_fiscal_idpk: number | null;
};
