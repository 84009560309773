import { notification } from 'antd';
import type { CardProperties } from 'components-old/merc/CardList';
import { useLoginStore } from 'features/login/store';
import { canUserListAllVendedores } from 'features/login/util';
import type { ApiNotaFiscalEvento } from 'features/nota-fiscal/types/ApiNotaFiscalEvento';
import type { FormNotaFiscalValues } from 'pages/nota-fiscal/forms/type/types';
import { type RootDispatch, type RootState, store } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { semTokenGet } from 'std/api/semToken';
import type { ApiResponseStatus } from 'std/api/types';
import type { ApiResponse } from 'std/api/types';
import { isResponseOk, throwIfResponseIsErr } from 'std/api/util';
import { base64ToStr } from 'std/base64';
import { registrosToCsv } from 'std/csv';
import { openPDF } from 'std/pdf';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { DefaultColors, TipoConsulta } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { RowNotaFiscal } from 'std/types/form/RowNotaFiscal';
import type { SortParams } from 'std/types/interfaces';
import type { RelatoriosPayload } from 'std/types/interfaces/RelatoriosPayload';
import { deepClone } from 'std/util';
import { downloadFile, downloadTextFile } from 'std/util';
import {
    EStatusNotaFiscal,
    type TCalcImpostosItens,
    type TCompartilhamentoDeNotaFiscal,
    type TEstornoResult,
    type TNotaFiscal,
    type TValorTotaisNf,
} from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            pesquisar?: string;
            data_inicio?: string;
            data_fim?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            tipo_consulta?: TipoConsulta;
        },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable, get } = notaFiscal;

        dispatch.notaFiscal.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        try {
            const {
                filterDateRange,
                filterPesquisar,
                filterTipo,
                filterFornecedor,
                filterCliente,
                filterNumero,
                filterSerie,
                filterStatus,
                filterVendedor,
                filterCidade,
                filterMarcador,
                registroInitial,
                qtdRegistros,
                sortParams,
                pagination,
                resetPagination,
            } = getTable;

            const {
                pesquisar,
                total_registros,
                registro_inicial,
                qtde_registros,
                sort,
                tipo_consulta = TipoConsulta.Resumida,
                ...restPayload
            } = payload;

            const ambiente = useLoginStore.getState().getEmpresa()?.emp_fiscal_ambiente;

            // MONTANDO OS PARAMETROS OBRIGATÓRIOS
            const params: BuildUrlParams = {
                data_inicio: filterDateRange?.start.format('DD-MM-YYYY'),
                data_fim: filterDateRange?.end.format('DD-MM-YYYY'),
                registro_inicial:
                    registro_inicial !== null && registro_inicial !== undefined
                        ? registro_inicial
                        : registroInitial,
                qtde_registros: qtde_registros || qtdRegistros,
                orderby: 'nof_data_emissao desc, nof_idpk desc',
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                marcadores: filterMarcador,
                tipo_consulta,
                ambiente,
                ...restPayload,
            };

            // CASO HOUVEREM FILTROS OPCIONAIS
            if (filterStatus) {
                params.status = JSON.stringify(filterStatus);
            }

            if (filterTipo) {
                params.tipo = filterTipo.idpk;
            }

            if (!canUserListAllVendedores()) {
                params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
            } else if (filterVendedor) {
                params.vendedor_idpk = filterVendedor.idpk;
            }

            if (filterCidade) {
                params.destinatario_cidade = filterCidade?.nome || '';
            }

            if (filterNumero) {
                params.numero = filterNumero;
            }

            if (filterSerie) {
                params.serie = filterSerie;
            }

            if (pesquisar || filterPesquisar) {
                params.pesquisar = pesquisar || filterPesquisar;
            }

            // filtros que usam param 'where'
            let where = '';

            if (filterFornecedor) {
                where = `nof_fornecedor_idpk=${filterFornecedor.idpk}`;
            }

            if (filterCliente) {
                where = `${where.length ? `${where} AND ` : ''}nof_cliente_idpk=${
                    filterCliente.idpk
                }`;
            }

            if (where.length) {
                params.where = where;
            }

            // CASO TENHA QUE ORDENAR OS DADOS (*OPCIONAL)
            if (sort?.shouldSort || sortParams?.shouldSort) {
                params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                    sort?.orderDirection || sortParams?.orderDirection
                }`;
            }

            // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
            if (total_registros) {
                params.total_registros = total_registros;
            }

            // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
            if (resetPagination) {
                params.registro_inicial = 0;
                params.total_registros = 'S';
            }

            const url = buildUrl(Endpoint.NotaFiscal, params);
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.notaFiscal.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    updateTable: false,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                    resetPagination: false,
                },
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async totalizador(_, state: RootState): Promise<void> {
        const { notaFiscal } = state;
        const { totalizador, getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const {
            filterDateRange,
            filterPesquisar,
            filterTipo,
            filterFornecedor,
            filterCliente,
            filterNumero,
            filterSerie,
            filterVendedor,
            filterCidade,
            filterMarcador,
        } = getTable;

        const ambiente = useLoginStore.getState().getEmpresa()?.emp_fiscal_ambiente;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            data_inicio: filterDateRange?.start.format('DD-MM-YYYY'),
            data_fim: filterDateRange?.end.format('DD-MM-YYYY'),
            marcadores: filterMarcador,
            pesquisar: filterPesquisar,
            ambiente,
        };

        if (filterTipo) {
            params.tipo = filterTipo.idpk;
        }

        if (!canUserListAllVendedores()) {
            params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
        } else if (filterVendedor) {
            params.vendedor_idpk = filterVendedor.idpk;
        }

        if (filterCidade) {
            params.destinatario_cidade = filterCidade?.nome;
        }

        if (filterNumero) {
            params.numero = filterNumero;
        }

        if (filterSerie) {
            params.serie = filterSerie;
        }

        // filtros que usam param 'where'
        let where = '';

        if (filterFornecedor) {
            where = `nof_fornecedor_idpk=${filterFornecedor.idpk}`;
        }

        if (filterCliente) {
            where = `${where.length ? `${where} AND ` : ''}nof_cliente_idpk=${filterCliente.idpk}`;
        }

        if (where.length) {
            params.where = where;
        }

        const url = buildUrl(Endpoint.NotaFiscalTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                Incorreta: DefaultColors.Orange,
                Aguardando: DefaultColors.Blue,
                Emitida: DefaultColors.Green,
                Cancelada: DefaultColors.Red,
                Rejeitada: DefaultColors.Purple,
                Estornada: DefaultColors.Gray,
                Total: DefaultColors.Black,
            };

            const totais: CardProperties[] = registros.map((total: TValorTotaisNf) => ({
                color: cores[total.tipo],
                title: total.tipo,
                amount: total.valor_total,
                number: total.quantidade_total,
                value: total.tipo,
            }));

            dispatch.notaFiscal.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { nof_idpk: number }, state: RootState): Promise<TNotaFiscal> {
        dispatch.notaFiscal.setState({
            getOne: startReduxFn(state.notaFiscal.getOne.data),
        });

        const { nof_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.NotaFiscal, params, nof_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const returnGetOne = registros && registros.length > 0 ? registros[0] : null;

            dispatch.notaFiscal.setState({
                getOne: endReduxFnOk(returnGetOne),
            });

            return returnGetOne;
        } catch (error) {
            dispatch.notaFiscal.setState({
                getOne: endReduxFnError(error),
            });
            return error;
        }
    },

    async post(payload: { body: FormNotaFiscalValues }, state: RootState): Promise<number | null> {
        dispatch.notaFiscal.setState({
            post: startReduxFn(state.notaFiscal.post.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscal, params);
            const response = await comTokenPost(url, payload.body);
            throwIfResponseIsErr(response);

            const nof_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].nof_idpk
                : 0;

            dispatch.notaFiscal.setState({
                post: endReduxFnOk({ nof_idpk }),
            });

            notification.success({
                message: 'Feito!',
                description: 'Nota Fiscal cadastrada',
            });

            return nof_idpk;
        } catch (error) {
            dispatch.notaFiscal.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar a nota fiscal!',
                description: error.message,
            });

            return null;
        }
    },

    async put(
        payload: {
            nof_idpk: number;
            body: FormNotaFiscalValues;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscal.setState({
            put: startReduxFn(state.notaFiscal.put.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const { nof_idpk = 0, body } = payload;

            const url = buildUrl(Endpoint.NotaFiscal, params, nof_idpk);
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            if (response.data.status === 'sucesso') {
                dispatch.notaFiscal.setState({
                    put: endReduxFnOk('Success'),
                });

                notification.success({
                    message: 'Feito!',
                    description: 'Nota Fiscal atualizada',
                });

                return response.data;
            }

            dispatch.notaFiscal.setState({
                put: endReduxFnOk('Error'),
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a nota fiscal!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { nof_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            remove: startReduxFn(state.notaFiscal.remove.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { nof_idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscal, params, nof_idpk);
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.notaFiscal.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.notaFiscal.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.notaFiscal.get({
                        total_registros: 'S',
                    });

                    dispatch.notaFiscal.totalizador({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Nota Fiscal excluída.',
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async preVisualizarDanfe(
        payload: { nof_idpk: number; tipo: number },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            preVisualizarDanfe: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { nof_idpk = 0, tipo = 0 } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalPreVisualizar, params, nof_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const {
                data: { xml = {} } = {},
            } = response || {};
            const { base64: base64Xml = '', fileName: fileNameXml = '' } = xml || {};

            if (tipo !== 2) {
                openPDF({
                    access: 'autenticado',
                    type: 'file',
                    relatorios: response.data as RelatoriosPayload,
                });
            } else {
                const text = base64ToStr(base64Xml);
                downloadTextFile(text, fileNameXml);
            }

            dispatch.notaFiscal.setState({
                preVisualizarDanfe: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async cancelarNotaFiscal(
        payload: {
            nof_idpk: number;
            justificativa: string;
            cancelar_venda?: 'S';
            cancelar_bonificacao?: 'S';
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscal.setState({
            cancelarNotaFiscal: startReduxFn(),
        });

        try {
            const { nof_idpk, updateTable, ...rest } = payload;

            const params = {
                ...rest,
                empresa_idpk: useLoginStore.getState().empresaIdpk,
            };

            const url = buildUrl(Endpoint.NotaFiscalCancelar, params, nof_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const { data } = response;

            if (data) {
                const { notaFiscal } = state;
                const {
                    getTable,
                    getTable: { selectedRow = undefined },
                } = notaFiscal || {};

                if (selectedRow) {
                    const newSelectedRow = deepClone(selectedRow) as RowNotaFiscal;

                    newSelectedRow.nof_nfe_pdf = data.evento_pdf;
                    newSelectedRow.nof_nfe_xml = data.evento_xml;

                    dispatch.notaFiscal.setState({
                        getTable: {
                            ...getTable,
                            selectedRow: newSelectedRow,
                        },
                    });
                }
            }

            dispatch.notaFiscal.setState({
                cancelarNotaFiscal: endReduxFnOk(data),
            });

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                cancelarNotaFiscal: endReduxFnError(error),
            });
        }
    },

    gerarRelatorio(payload: { link: string; open: boolean }, state: RootState): void {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            gerarRelatorio: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { link, open } = payload;

        if (open) {
            openPDF({
                access: 'autenticado',
                type: 'link',
                link,
                autoPrint: true,
            });
        } else {
            downloadFile(link, 'notafiscal.xml');
        }

        dispatch.notaFiscal.setState({
            gerarRelatorio: endReduxFnOk(undefined),
            getTable: {
                ...getTable,
                loadingTable: false,
            },
        });
    },

    async gerarRelatorioMultiple(payload: number[], state: RootState): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            gerarRelatorioMultiple: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            nota_fiscal_list: JSON.stringify(payload),
            gerar_fatura: 'S',
        };

        const url = buildUrl(Endpoint.NotaFiscalGerarDanfes, params);

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            openPDF({
                access: 'autenticado',
                type: 'file',
                relatorios: response.data as RelatoriosPayload,
            });

            dispatch.notaFiscal.setState({
                gerarRelatorioMultiple: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                gerarRelatorioMultiple: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async emitirNotaFiscal(
        payload: {
            itens: RowNotaFiscal[];
            sendToPrint?: boolean;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<ApiResponseStatus> {
        dispatch.notaFiscal.setState({
            emitirNotaFiscal: {
                data: null,
                error: null,
                loading: true,
                cancel: false,
            },
        });

        const { sendToPrint, itens, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        let hasError = false;

        const notasComErro: Array<{
            nof_idpk: number;
            nof_numero: number;
            motivoErro: string;
        }> = [];

        const idpksSuccess: number[] = [];

        try {
            // TODO trocar async for por Promisse.All
            // for (const [index, item] of itens.entries()) {
            for (let index = 0; index < itens.length; index += 1) {
                const item = itens[index] as RowNotaFiscal;

                const { notaFiscal } = store.getState() as RootState;
                const {
                    emitirNotaFiscal: { cancel = false } = {},
                    getTable,
                } = notaFiscal || {};

                if (cancel) {
                    item.emitirCancel = true;

                    dispatch.notaFiscal.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;

                    throw new Error('Emissão da nota fiscal cancelada pelo usuário');
                }

                item.loading = true;

                dispatch.notaFiscal.setState({
                    getTable: {
                        ...getTable,
                        selectedRows: [...itens.slice(0, index), item, ...itens.slice(index + 1)],
                    },
                });

                const url = buildUrl(Endpoint.NotaFiscalEnviar, params, item.key);
                const response = await comTokenPost(url);

                if (!isResponseOk(response)) {
                    const apiErrorMessage = response?.data?.informacao;

                    item.nof_status = 2;
                    item.loading = false;

                    if (apiErrorMessage) {
                        item.motivoErro = apiErrorMessage.map((errors) => errors?.mensagem || '');
                    } else {
                        item.motivoErro = response?.data?.mensagem;
                    }

                    dispatch.notaFiscal.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    hasError = true;

                    notasComErro.push({
                        nof_idpk: item.nof_idpk || 0,
                        nof_numero: response?.data?.nof_numero || 0,
                        motivoErro: item.motivoErro || '',
                    });

                    continue;
                }

                const {
                    motivo,
                    mensagem = 'Falha ao tentar emitir a nota fiscal!',
                    nof_nfe_pdf = '',
                    nof_nfe_xml = '',
                    nof_status = EStatusNotaFiscal.Emitida,
                } = response?.data || {};

                if (nof_status !== EStatusNotaFiscal.Emitida) {
                    item.nof_status = nof_status;
                    item.loading = false;
                    item.motivoErro = motivo || mensagem;

                    dispatch.notaFiscal.setState({
                        getTable: {
                            ...getTable,
                            selectedRows: [
                                ...itens.slice(0, index),
                                item,
                                ...itens.slice(index + 1),
                            ],
                        },
                    });

                    notasComErro.push({
                        nof_idpk: item.nof_idpk || 0,
                        nof_numero: response?.data?.nof_numero || 0,
                        motivoErro: item.motivoErro || '',
                    });

                    hasError = true;

                    continue;
                }

                item.nof_nfe_pdf = nof_nfe_pdf;
                item.nof_nfe_xml = nof_nfe_xml;
                item.nof_status = nof_status;
                item.loading = false;
                item.emitirOk = true;

                dispatch.notaFiscal.setState({
                    getTable: {
                        ...getTable,
                        selectedRows: [...itens.slice(0, index), item, ...itens.slice(index + 1)],
                    },
                });

                idpksSuccess.push(item.key);
            }

            if (sendToPrint && idpksSuccess.length > 0) {
                dispatch.notaFiscal.gerarRelatorioMultiple(idpksSuccess);
            }

            const { notaFiscal, vendasExternas } = state;
            const { showDrawerEmitirNf, getTable } = notaFiscal || {};

            if (showDrawerEmitirNf || vendasExternas.showDrawerEmitirNf) {
                dispatch.notaFiscal.setState({
                    emitirNotaFiscal: {
                        data: hasError ? notasComErro : 'ok',
                        loading: false,
                        error: hasError ? 'Houve um erro em alguma das notas' : null,
                        cancel: false,
                    },
                });
            } else {
                const ajustRowsSelected = itens
                    .filter((row) => !row.emitirOk)
                    .map((row) => {
                        row.emitirCancel = false;
                        row.emitirOk = false;
                        row.motivoErro = undefined;
                        row.loading = false;
                        return row;
                    });

                dispatch.notaFiscal.setState({
                    emitirNotaFiscal: {
                        data: 'ok',
                        loading: false,
                        error: null,
                        cancel: false,
                    },
                    getTable: {
                        ...getTable,
                        selectedRows: ajustRowsSelected,
                    },
                });
            }

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }

            return 'sucesso';
        } catch (error) {
            dispatch.notaFiscal.setState({
                emitirNotaFiscal: {
                    data: 'ok',
                    loading: false,
                    error,
                    cancel: false,
                },
            });

            return 'erro';
        }
    },

    async compartilharSend(payload: {
        idpk: number;
        tipo: string;
        template_id: string;
        tags?: { nome: string; valor?: string }[];
        fin_email?: string | null;
        fin_email_cc?: string;
    }): Promise<void> {
        dispatch.notaFiscal.setState({
            compartilharSend: startReduxFn(),
        });

        const { idpk, tags, fin_email, fin_email_cc, ...rest } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...rest,
        };
        const bodySend = payload.tipo !== 'W' ? { tags, fin_email, fin_email_cc } : undefined;

        const url = buildUrl(Endpoint.NotaFiscalCompartilhar, params, idpk);

        try {
            const response = await comTokenPost(url, bodySend);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                compartilharSend: endReduxFnOk(undefined),
                showDrawerCompartilhar: false,
            });

            if (payload.tipo === 'E') {
                notification.success({
                    message: 'Email enviado',
                    description:
                        'Atenção, a notificação pode demorar até 5 minutos para ser entregue.',
                });
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                compartilharSend: endReduxFnError(error),
            });

            if (payload.tipo === 'E') {
                notification.error({
                    message: 'Falhou!',
                    description:
                        (error as { message: string })?.message || 'Não foi possível enviar!',
                });
            }
        }
    },

    async gerarCartaCorrecao(payload: { link: string; tipo: number }): Promise<void> {
        dispatch.notaFiscal.setState({
            gerarCartaCorrecao: startReduxFn(),
        });

        const { link, tipo } = payload;

        if (tipo === 1) {
            openPDF({
                access: 'autenticado',
                type: 'link',
                link,
            });
        } else {
            const filename = link.split('/').at(-1) || 'file.xml';
            downloadFile(link, filename);
        }

        dispatch.notaFiscal.setState({
            gerarCartaCorrecao: endReduxFnOk(undefined),
        });
    },

    async sendCartaCorrecao(
        payload: { nof_idpk: number; texto: string },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscal.setState({
            sendCartaCorrecao: startReduxFn(state.notaFiscal.sendCartaCorrecao.data),
        });

        const { nof_idpk, texto, ...rest } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.NotaFiscalCartaCorrecao, params, nof_idpk);

        try {
            const response = await comTokenPost(url, { texto, ...rest });
            throwIfResponseIsErr(response);

            const { data = undefined } = response || {};

            if (data) {
                const { notaFiscal } = state;
                const {
                    getTable,
                    getTable: { selectedRow = undefined },
                } = notaFiscal || {};

                if (selectedRow) {
                    const newSelectedRow = deepClone(selectedRow) as RowNotaFiscal;

                    const newEvento: ApiNotaFiscalEvento = {
                        nfe_texto: texto,
                        nfe_pdf: data.evento_pdf,
                        nfe_xml: data.evento_xml,
                        nfe_data_hora: data.nfe_data_hora,
                        nfe_status_descricao: data.nfe_status_descricao || ' ',
                        nfe_evento_tipo: data.nfe_evento_tipo || 3,
                    } as ApiNotaFiscalEvento;

                    // Inserindo em eventos
                    (newSelectedRow.eventos || []).push(newEvento);

                    dispatch.notaFiscal.setState({
                        getTable: {
                            ...getTable,
                            selectedRow: newSelectedRow,
                        },
                    });
                }
            }

            dispatch.notaFiscal.setState({
                sendCartaCorrecao: endReduxFnOk(data),
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                sendCartaCorrecao: endReduxFnError(error),
            });
        }
    },

    async calcularImpostos(payload: TCalcImpostosItens, state: RootState): Promise<void> {
        dispatch.notaFiscal.setState({
            calcularImpostos: startReduxFn(state.notaFiscal.calcularImpostos.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalCalcularImpostos, params);
            const response = await comTokenPost(url, payload);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                calcularImpostos: endReduxFnOk(response.data.registros?.[0]),
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                calcularImpostos: endReduxFnError(error),
            });
        }
    },

    async getReceitaFiscal(
        payload: { nof_idpk: number },
        state: RootState,
    ): Promise<{ status: ApiResponseStatus; mensagem: string }> {
        dispatch.notaFiscal.setState({
            getReceitaFiscal: startReduxFn(state.notaFiscal.getReceitaFiscal.data),
        });

        try {
            const { nof_idpk } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalConsultar, params, nof_idpk);
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                getReceitaFiscal: endReduxFnOk(response.data.mensagem),
            });

            return { status: 'sucesso', mensagem: response.data.mensagem };
        } catch (error) {
            dispatch.notaFiscal.setState({
                getReceitaFiscal: endReduxFnError(error),
            });

            return { status: 'erro', mensagem: `${error}` };
        }
    },

    async lancarEstoque(
        payload: { nof_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            lancarEstoque: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { nof_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn estornar
            movimentar: 'S',
        };

        const url = buildUrl(Endpoint.NotaFiscalEstoque, params, nof_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                lancarEstoque: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                lancarEstoque: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async estornarEstoque(
        payload: { nof_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            estornarEstoque: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { nof_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn lançar
            estornar: 'S',
        };

        const url = buildUrl(Endpoint.NotaFiscalEstoque, params, nof_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                estornarEstoque: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                estornarEstoque: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async lancarFinanceiro(
        payload: { nof_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            lancarFinanceiro: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { nof_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn estornar
            movimentar: 'S',
        };

        const url = buildUrl(Endpoint.NotaFiscalFinanceiro, params, nof_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                lancarFinanceiro: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                lancarFinanceiro: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async estornarFinanceiro(
        payload: { nof_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { notaFiscal } = state;
        const { getTable } = notaFiscal;

        dispatch.notaFiscal.setState({
            estornarFinanceiro: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { nof_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn lançar
            estornar: 'S',
        };

        const url = buildUrl(Endpoint.NotaFiscalFinanceiro, params, nof_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                estornarFinanceiro: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                estornarFinanceiro: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async getCompartilhamentoDeNotaFiscal(
        payload: {
            id: string;
            registrarVisualizacao: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscal.setState({
            getCompartilhamentoDeNotaFiscal: startReduxFn(
                state.notaFiscal.getCompartilhamentoDeNotaFiscal.data,
            ),
        });

        const { id, registrarVisualizacao } = payload;

        const params = {
            id,
            ...(registrarVisualizacao && { registrar_visualizacao: 'S' }),
        };

        const url = buildUrl(Endpoint.NotaFiscalLinkCompartilhamento, params);

        try {
            const response = await semTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                getCompartilhamentoDeNotaFiscal: endReduxFnOk(
                    response.data as TCompartilhamentoDeNotaFiscal,
                ),
            });
        } catch (error) {
            dispatch.notaFiscal.setState({
                getCompartilhamentoDeNotaFiscal: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async gerarEstorno(
        payload: {
            justificativa: string;
            nota_fiscal_idpk: number;
            updateTable?: boolean;
            cfop?: number;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscal.setState({
            gerarEstorno: startReduxFn(state.notaFiscal.gerarEstorno.data),
        });

        const { updateTable, cfop, ...rest } = payload;

        const params = {
            ...rest,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            cfop,
        };

        const url = buildUrl(Endpoint.NotaFiscalGerarEstorno, params);

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                gerarEstorno: endReduxFnOk(response.data as TEstornoResult),
            });

            if (updateTable) {
                dispatch.notaFiscal.get({});
            }
        } catch (error) {
            dispatch.notaFiscal.setState({
                gerarEstorno: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async enviarExpedicao(payload: { nof_idpk: number }, state: RootState): Promise<void> {
        const { getTable } = state.notaFiscal;
        const { nof_idpk } = payload;

        dispatch.notaFiscal.setState({
            enviarExpedicao: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalEnviarExpedicao, params, nof_idpk);
            const response: ApiResponse = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscal.setState({
                enviarExpedicao: endReduxFnOk(undefined),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            dispatch.notaFiscal.get({});
        } catch (error) {
            dispatch.notaFiscal.setState({
                enviarExpedicao: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async exportarCSV(_, state: RootState): Promise<void> {
        const {
            getTable: { selectedRows },
        } = state.notaFiscal;

        const where = `(nof_idpk in (${selectedRows.map((row) => row.nof_idpk).join(',')}))`;

        try {
            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                where,
                fields: `nof_numero,nof_serie,nof_data_emissao,nof_total_nota,volume, 
                    nof_cliente_idpk,nof_dest_razao_social,nof_dest_cidade,nof_dest_uf,
                    nof_transp_nome,nof_usuario_nome`,
            };

            const url = buildUrl(Endpoint.NotaFiscal, params);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            if (!response.data.registros) {
                return;
            }

            const csvData = response.data.registros.map((row) => ({
                'Nro Nota': `${row.nof_numero}/${row.nof_serie}`,
                Lançamento: row.nof_data_emissao,
                Total: row.nof_total_nota,
                Volumes: row.volume?.nfv_quantidade,
                'Peso Bruto': row.volume?.nfv_peso_bruto,
                'Peso Liq.': row.volume?.nfv_peso_liquido,
                'Cliente:': row.nof_cliente_idpk,
                Nome: row.nof_dest_razao_social,
                Cidade: row.nof_dest_cidade,
                UF: row.nof_dest_uf,
                'Nome Transportador': row.nof_transp_nome,
                Vendedor: row.nof_usuario_nome,
            }));

            registrosToCsv(csvData, 'tabela-notas-fiscais');
        } catch (e) {
            console.error(e);
        }
    },
});
