import { useParams } from '@tanstack/react-router';
import { HelmetWithBreadcrumbs } from 'components-old/merc/HelmetWithBreadcrumbs';
import { useSelector } from 'react-redux';
import type { AppState } from 'state';
import { DrawerContactInfo } from '../DrawerContactInfo';
import { FloatingWhatsappButton } from '../FloatingWhatsappButton';
import { Footer } from '../Footer';
import { TopBar } from '../TopBar';
import { ProductInfo } from './ProductInfo';

export function ProdutoPage() {
    const { idpk } = useParams({ from: '/p/catalogo/$slug/produto/$idpk' });
    const empresa = useSelector(
        (state: AppState) => state.catalogoProdutos.getCatalogoHeaderData.data?.empresa,
    );

    return (
        <div className='relative' style={{ backgroundColor: 'whitesmoke' }}>
            <HelmetWithBreadcrumbs
                title={empresa?.emp_nome_fantasia || empresa?.emp_nome_razaosocial || ''}
            />
            <TopBar proIdpk={idpk} />
            <DrawerContactInfo />
            <ProductInfo proIdpk={idpk} />
            <Footer />
            <FloatingWhatsappButton />
        </div>
    );
}
