import { newRequest } from 'std/api/newRequest';
import type { ApiVendaItem, ApiVendaItemDetalhado } from './types';

export type ApiVendaItemBody = Partial<ApiVendaItem>;

export function apiVendaItemGet({
    idpk,
    params,
}: {
    idpk?: number;
    params: {
        venda_idpk: number;
    };
}): Promise<ApiVendaItemDetalhado[]> {
    return newRequest({
        url: 'Venda/Item',
        type: 'get',
        idpk,
        params,
    });
}

export function apiVendaItemPost({
    params,
    body,
}: {
    params: {
        venda_idpk: number;
    };
    body: ApiVendaItemBody;
}): Promise<{ vei_idpk: number; vei_venda_idpk: number }[]> {
    return newRequest({
        url: 'Venda/Item',
        type: 'post',
        params,
        body,
    });
}

export function apiVendaItemPut({
    idpk,
    params,
    body,
}: {
    idpk: number;
    params: {
        venda_idpk: number;
    };
    body: ApiVendaItemBody;
}): Promise<unknown> {
    return newRequest({
        url: 'Venda/Item',
        type: 'put',
        idpk,
        params,
        body,
    });
}

export function apiVendaItemDelete({
    idpk,
    params,
}: { idpk: number; params: { venda_idpk: number } }): Promise<unknown> {
    return newRequest({
        url: 'Venda/Item',
        type: 'delete',
        idpk,
        params,
    });
}
