import type { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { nanoid } from 'nanoid';
import type { MenuData } from '../types';
import { useGetMenuData } from '../useGetMenuData';
import { getItemMenu } from '../utils';
import { MenuItemLink } from './MenuItemLink';
import style from './style.module.scss';

export function useGenerateMenuItems(): ItemType<MenuItemType>[] {
    const menuData = useGetMenuData();

    const generateItem = (item: MenuData): ItemType<MenuItemType> => {
        const { category, key, title, url, params, search, icon } = item;

        if (category) {
            return getItemMenu(item.title, nanoid(), undefined, undefined, 'group');
        }

        if (url) {
            const children = (
                <MenuItemLink url={url} params={params} search={search} title={title} icon={icon} />
            );

            return getItemMenu(children, key || title, undefined, undefined, undefined);
        }

        const children = (
            <>
                <span className={style.title}>{title}</span>
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
            </>
        );

        return getItemMenu(children, key || title, undefined, undefined, undefined);
    };

    const generateSubmenu = (items: MenuData[]): ItemType<MenuItemType>[] => {
        return items.map((menuItem) => {
            if (menuItem.hide) {
                return null;
            }

            if (menuItem.children) {
                const subMenuTitle = (
                    <span key={menuItem.key}>
                        <span className={style.title}>{menuItem.title}</span>
                        {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
                    </span>
                );

                return getItemMenu(
                    subMenuTitle,
                    menuItem.key || menuItem.title,
                    null,
                    generateSubmenu(menuItem.children).filter((f) => f !== null),
                );
            }

            return generateItem(menuItem);
        });
    };

    return menuData.map((menuItem: MenuData) => {
        if (menuItem.hide) {
            return null;
        }

        if (menuItem.children) {
            const subMenuTitle = (
                <span key={menuItem.key}>
                    <span className={style.title}>{menuItem.title}</span>
                    {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
                </span>
            );

            return getItemMenu(
                subMenuTitle,
                menuItem.key || menuItem.title,
                null,
                generateSubmenu(menuItem.children).filter((f) => f !== null),
            );
        }

        return generateItem(menuItem);
    });
}
