// https://doc.rust-lang.org/std/result/enum.Result.html

interface IResult<T, E> {
    /** Returns true if the result is Ok */
    isOk(): this is Ok<T, E>;

    /** Returns true if the result is Ok and the value inside of it matches a predicate */
    isOkAnd(fn: (value: T) => boolean): boolean;

    /** Returns true if the result is Err */
    isErr(): this is Err<T, E>;

    /** Returns true if the result is Err and the value inside of it matches a predicate */
    isErrAnd(fn: (value: E) => boolean): boolean;

    /** Converts from Result<T, E> to T | null */
    ok(): T | null;

    /** Converts from Result<T, E> to E | null */
    err(): E | null;

    /** Maps a Result<T, E> to Result<U, E> by applying a function to a contained Ok value, leaving an Err value untouched **/
    map<U>(fn: (value: T) => U): Result<U, E>;

    /** Returns the provided default (if Err), or applies a function to the contained value (if Ok) */
    mapOr<U>(defaultValue: U, fn: (value: T) => U): U;

    /** Maps a Result<T, E> to U by applying fallback function default to a contained Err value, or function f to a contained Ok value */
    mapOrElse<U>(defaultFn: (error: E) => U, fn: (value: T) => U): U;

    /** Maps a Result<T, E> to Result<T, F> by applying a function to a contained Err value, leaving an Ok value untouched */
    mapErr<F>(fn: (error: E) => F): Result<T, F>;

    /** Calls a function with a reference to the contained value if Ok */
    inspect(fn: (value: T) => void): this;

    /** Calls a function with a reference to the contained value if Err. */
    inspectErr(fn: (error: E) => void): this;

    /** Returns the contained Ok value. Throw if Err */
    expect(msg: string): T;

    /** Returns the contained Ok value. Throw if Err */
    unwrap(): T;

    /** Returns the contained Err value. Throw if Ok */
    expectErr(msg: string): E;

    /** Returns the contained Err value. Throw if Ok */
    unwrapErr(): E;

    /** Returns res if the result is Ok, otherwise returns the Err value of self */
    and<U>(result: Result<U, E>): Result<U, E>;

    /** Calls op if the result is Ok, otherwise returns the Err value */
    andThen<U>(fn: (value: T) => Result<U, E>): Result<U, E>;

    /** Returns res if the result is Err, otherwise returns the Ok value */
    or<F>(result: Result<T, F>): Result<T, F>;

    /** Calls op if the result is Err, otherwise returns the Ok value */
    orElse<F>(fn: (error: E) => Result<T, F>): Result<T, F>;

    /** Returns the contained Ok value or a provided default */
    unwrapOr(defaultValue: T): T;

    /** Returns the contained Ok value or computes it from a closure */
    unwrapOrElse(defaultFn: (error: E) => T): T;
}

export class Ok<T, E> implements IResult<T, E> {
    value: T;

    constructor(value: T) {
        this.value = value;
    }

    isOk(): this is Ok<T, E> {
        return true;
    }

    isOkAnd(fn: (value: T) => boolean): boolean {
        return fn(this.value);
    }

    isErr(): this is Err<T, E> {
        return false;
    }

    isErrAnd(): boolean {
        return false;
    }

    ok(): T | null {
        return this.value;
    }

    err(): E | null {
        return null;
    }

    map<U>(fn: (value: T) => U): Result<U, E> {
        return ok(fn(this.value));
    }

    mapOr<U>(_: U, fn: (value: T) => U): U {
        return fn(this.value);
    }

    mapOrElse<U>(_: (error: E) => U, fn: (value: T) => U): U {
        return fn(this.value);
    }

    mapErr<F>(): Result<T, F> {
        return ok(this.value);
    }

    inspect(fn: (value: T) => void): this {
        fn(this.value);
        return this;
    }

    inspectErr(): this {
        return this;
    }

    expect(): T {
        return this.value;
    }

    unwrap(): T {
        return this.value;
    }

    expectErr(msg: string): never {
        console.error('Ok.expectErr', { msg, value: this.value });
        throw new Error(`Ok.expectErr - ${msg}`);
    }

    unwrapErr(): never {
        console.error('Ok.unwrapErr', { value: this.value });
        throw new Error('Ok.unwrapErr');
    }

    and<U>(result: Result<U, E>): Result<U, E> {
        return result;
    }

    andThen<U>(fn: (value: T) => Result<U, E>): Result<U, E> {
        return fn(this.value);
    }

    or<F>(): Result<T, F> {
        return ok(this.value);
    }

    orElse<F>(): Result<T, F> {
        return ok(this.value);
    }

    unwrapOr(): T {
        return this.value;
    }

    unwrapOrElse(): T {
        return this.value;
    }
}

export class Err<T, E> implements IResult<T, E> {
    error: E;

    constructor(error: E) {
        this.error = error;
    }

    isOk(): this is Ok<T, E> {
        return false;
    }

    isOkAnd(): boolean {
        return false;
    }

    isErr(): this is Err<T, E> {
        return true;
    }

    isErrAnd(fn: (value: E) => boolean): boolean {
        return fn(this.error);
    }

    ok(): T | null {
        return null;
    }

    err(): E | null {
        return this.error;
    }

    map<U>(): Result<U, E> {
        return err(this.error);
    }

    mapOr<U>(defaultValue: U): U {
        return defaultValue;
    }

    mapOrElse<U>(defaultFn: (error: E) => U): U {
        return defaultFn(this.error);
    }

    mapErr<F>(fn: (error: E) => F): Result<T, F> {
        return err(fn(this.error));
    }

    inspect(): this {
        return this;
    }

    inspectErr(fn: (error: E) => void): this {
        fn(this.error);
        return this;
    }

    expect(msg: string): never {
        console.error('Err.expect()', {
            msg,
            error: this.error,
        });

        throw new Error(`Err.expect() - ${msg}`);
    }

    unwrap(): never {
        console.error('Err.unwrap()', { error: this.error });
        throw new Error('Err.unwrap()');
    }

    expectErr(): E {
        return this.error;
    }

    unwrapErr(): E {
        return this.error;
    }

    and<U>(): Result<U, E> {
        return err(this.error);
    }

    andThen<U>(): Result<U, E> {
        return err(this.error);
    }

    or<F>(result: Result<T, F>): Result<T, F> {
        return result;
    }

    orElse<F>(fn: (error: E) => Result<T, F>): Result<T, F> {
        return fn(this.error);
    }

    unwrapOr(defaultValue: T): T {
        return defaultValue;
    }

    unwrapOrElse(fn: (error: E) => T): T {
        return fn(this.error);
    }
}

export type Result<T, E> = Ok<T, E> | Err<T, E>;
export type AsyncResult<T, E> = Promise<Result<T, E>>;

export function ok<T, E = T>(value: T): Result<T, E> {
    return new Ok(value);
}

export function err<E, T = E>(error: E): Result<T, E> {
    return new Err(error);
}

export function match<T, E, U>(
    result: Result<T, E>,
    obj: {
        ok: (value: T) => U;
        err: (error: E) => U;
    },
): U {
    if (result.isOk()) {
        return obj.ok(result.value);
    }

    return obj.err(result.error);
}

export function safeTry<T>(fn: () => T): Result<T, any> {
    try {
        return ok(fn());
    } catch (error) {
        return err(error);
    }
}

export async function safeTryAsync<T>(fn: () => Promise<T>): AsyncResult<T, any> {
    try {
        return ok(await fn());
    } catch (error) {
        return err(error);
    }
}
