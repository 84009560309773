export enum DefaultColors {
    Black = '#000000',
    White = '#ffffff',
    Red = 'rgb(245, 34, 46)',
    Blue = '#1d39c4',
    Green = '#389e0d',
    DarkGreen = '#256809',
    Purple = '#531dab',
    Yellow = '#fffb00',
    Orange = '#ff9e00',
    DarkOrange = '#cc6b00',
    Gray = '#595c97',
}

// Cores padrão do componente tag
export enum DefaultTagColors {
    magenta = 'magenta',
    red = 'red',
    volcano = 'volcano',
    orange = 'orange',
    gold = 'gold',
    lime = 'lime',
    green = 'green',
    cyan = 'cyan',
    blue = 'blue',
    geekblue = 'geekblue',
    purple = 'purple',
    gray = 'gray',
    success = 'success',
    processing = 'processing',
    error = 'error',
    warning = 'warning',
    default = 'default',
}

export enum AmbienteFiscal {
    PRODUCAO = 1,
    HOMOLOGACAO = 2,
}

export enum TipoEmpresa {
    Associacao = 'ASSOCIACAO',
    Individual = 'INDIVIDUAL',
    LTDA = 'LTDA',
    MEI = 'MEI',
}

export const formEditMode = ['cadastrar', 'editar', 'clonar', 'visualizar', 'outro'] as const;
export type FormEditMode = (typeof formEditMode)[number];

export enum Status {
    Ok = 'sucesso',
    Err = 'erro',
}

export enum TipoDesconto {
    VALOR_MOEDA = '$',
    PORCENTAGEM = '%',
}

export enum TipoNotaFiscal {
    Compra = 1,
    Venda = 2,
    Remessa = 3,
    Retorno = 4,
    Devolução = 5,
    Ajuste = 6,
    Complementar = 7,
    Estorno = 8,
    Importada = 9,
}

export enum DataDe {
    Insercao = 'data_de',
    Vencimento = 'vencimento_de',
    Recebimento = 'pagamento_de',
}

export enum DataAte {
    Insercao = 'data_ate',
    Vencimento = 'vencimento_ate',
    Recebimento = 'pagamento_ate',
}

export enum TipoCobranca {
    Pix = 'pix',
    Boleto = 'boleto',
    Bolepix = 'bolepix',
    PixCobranca = 'pix_cobranca',
    PixInstantaneo = 'pix_instantaneo',
}

export enum LarguraColunas {
    label = '3rem',
    acoes = '4rem',
    acoesBtn = '6.5rem',
    cidades = '7rem',
    codigo = '6rem',
    codigoComTooltip = '7rem',
    codigoInterno = '7.5rem',
    codigoSKU = '7rem',
    cnpj = '9rem',
    data = '8rem',
    dataComHora = '10rem',
    eventos = '13rem',
    estoque = '7rem',
    editar = '7.5rem',
    estornar = '5rem',
    link = '3.5rem',
    movimento = '7rem',
    parcela = '4.7rem',
    porcentagem = '3rem',
    peso = '8rem',
    quantidade = '5rem',
    quantidadeObjetos = '8rem',
    serie = '2rem',
    rowSelect = '2.3rem',
    rowExpand = '2.3rem',
    status = '7rem',
    telefone = '7.5rem',
    textShort = '6rem',
    textLong = '10rem',
    textExtraLong = '15rem',
    tipoVendas = '5.5rem',
    tipoCobranca = '4rem',
    valor = '8.5rem',
}

export enum TipoConsulta {
    Detalhada = 'D',
    Resumida = 'R',
}
