import type { ApiVendaTipo } from 'features/venda/types';

export function isTipoVendaValido(value: string): boolean {
    return (
        value === 'pedido' || value === 'venda' || value === 'orçamento' || value === 'bonificação'
    );
}

export function isTipoVendaMasculino(value: ApiVendaTipo): boolean {
    return value === 'orçamento' || value === 'pedido';
}

export function getCraseVendaTipo(value: ApiVendaTipo): 'à' | 'ao' {
    return isTipoVendaMasculino(value) ? 'ao' : 'à';
}

export function isVendaCancelada(value: string): boolean {
    return ['Cancelada', 'Cancelado', 'Recusada', 'Recusado'].includes(value);
}
