import { roundAbnt } from 'std/math';

export function numberToCurrency(value: string | number | null | undefined): string {
    if (typeof value === 'string') {
        return numberToString(Number(value), 'currency', 2);
    }

    return numberToString(value, 'currency', 2);
}

export function numberToPercent(value: string | number | null | undefined): string {
    if (typeof value === 'string') {
        return numberToString(stringToNumber(value), 'percent', 2);
    }

    return numberToString(value, 'percent', 2);
}

export function numberToDecimal(value: number | undefined | null, decimal = 2): string {
    return numberToString(value, 'decimal', decimal);
}

/** Verifica a quantidade de casas decimais de um número. ex: 2.453 > 3 */
export function verifyQtdDecimals(value: number | null | undefined): number {
    if (value === null || value === undefined) {
        return 0;
    }

    const splitDecimal = value.toString().split('.')[1] || '';
    const totalDecimal = splitDecimal.length || 0;
    return totalDecimal;
}

/** Converte um número em string, com opções de formatação */
export function numberToString(
    value: number | undefined | null,
    style: 'decimal' | 'percent' | 'currency' = 'decimal',
    /* Se não informado vai manter as casas até no máximo 4. Se 0 vai arredondar o valor para inteiro. */
    decimalScale?: number,
): string {
    if (typeof value !== 'number') {
        return '';
    }

    let innerValue = value;

    if (decimalScale === 0) {
        innerValue = roundAbnt(value);
    }

    if (style === 'percent') {
        innerValue = value / 100;
    }

    let result = innerValue
        .toLocaleString('pt-BR', {
            style,
            currency: style === 'currency' ? 'BRL' : undefined,
            minimumFractionDigits: decimalScale === 0 ? 0 : decimalScale,
            maximumFractionDigits: decimalScale === 0 ? 0 : decimalScale || 4,
        })
        .replaceAll('\u00A0', ' ');

    // Caso tiver mais que duas casas decimais
    // Verifica se tem zeros a mais
    // Se sim remove
    if (!decimalScale || (decimalScale && decimalScale > 2)) {
        const parts = result.split(',');

        if (parts[1] && parts[1].length > 2) {
            result = result.replace(/0+$/, ''); // Remove zeros extras
        }

        // Evitar "15,"
        if (result.endsWith(',')) {
            result = `${result}00`;
        }

        // Evitar "15,1"
        if (result.at(-2) === ',') {
            result = `${result}0`;
        }
    }

    if (style === 'percent') {
        return result.replace('%', ' %');
    }

    return result;
}

/** Converte uma string para número. Suporta valores monetários, decimais e porcentagens. */
export function stringToNumber(value: string | undefined | null): number {
    if (typeof value !== 'string') {
        return 0;
    }

    // remove qualquer char que nao seja numero , ou -
    const num = Number.parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.'));

    if (Number.isNaN(num)) {
        return 0;
    }

    return num;
}

/** Formata um CPF/CNPJ com pontuação */
export function formatDocument(documento: Optional<string>): string {
    if (typeof documento !== 'string' || documento === '' || documento.replaceAll(' ', '') === '') {
        return '';
    }

    // formatação para CNPJ
    if (documento.length === 14) {
        return documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    // formatação para CPF
    if (documento.length === 11) {
        return documento
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    return documento;
}

/** Formata o número de um telefone */
export function formatTelefone(value: string | number | undefined | null): string {
    if (value === null || value === undefined) {
        return '';
    }

    if (typeof value === 'string' && (value === '' || value.replaceAll(' ', '') === '')) {
        return '';
    }

    let innerValue: string;

    if (typeof value === 'number') {
        innerValue = value.toString();
    } else {
        innerValue = value;
    }

    return innerValue
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
        .replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen antes dos últimos quatro dígitos
}

/** Formata número de série, preenchendo com zeros à esquerda */
export function formatNumeroSerie(numero: number, serie: number): string {
    return `${String(numero).padStart(8, '0')}/${String(serie).padStart(3, '0')}`;
}

/** Formata cep com a máscara XX.XXX-XXX */
export function formatCep(value: string | null | undefined): string {
    if (typeof value !== 'string' || value === '' || value.replaceAll(' ', '') === '') {
        return '';
    }

    if (value.length !== 8) {
        return value;
    }

    return value.replace(/(\w{3}$)/, '-$1').replace(/(^\w{2})/, '$1.');
}

export function onlyNumbers(value: string | undefined | null): string {
    if (!value) {
        return '';
    }

    return value.replace(/\D/g, '');
}

export function formatCreditCardNumber(value: string | undefined | null): string {
    if (!value) {
        return '';
    }

    const valueNoSpace = value.replaceAll(' ', '');

    if (valueNoSpace === '') {
        return '';
    }

    const p1 = valueNoSpace.substring(0, 4);
    const p2 = valueNoSpace.substring(4, 8);
    const p3 = valueNoSpace.substring(8, 12);
    const p4 = valueNoSpace.substring(12, 16);

    const res = `${p1} ${p2} ${p3} ${p4}`;
    return res.trim();
}
