// https://www.projetoacbr.com.br/forum/topic/9709-minha-pequena-contribui%C3%A7%C3%A3o-arredondamento-abnt/?do=findComment&comment=132759&_rid=75039
/** Arredonda um número conforme ABNT NBR 5891 */
export function roundAbnt(value: number, decimalScale = 2): number {
    const result = value;

    const split = value.toString().split('.');
    const decimal = split[1];

    if (decimalScale < 1 || !decimal || decimal.length <= decimalScale) {
        return value;
    }

    // Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
    if (decimal.substring(decimalScale, 1) !== '5') {
        return Number.parseFloat(result.toFixed(decimalScale));
    }

    // Se a casa decimal SUBSEQUENTE for IGUAL a 5
    if (decimal.substring(decimalScale, 1) === '5') {
        let conservedDecimalPlace = Number.parseInt(decimal.substring(decimalScale - 1, 1), 10);

        // Se a casa decimal que será CONSERVADA, for IMPAR
        if (conservedDecimalPlace % 2 !== 0) {
            return Number.parseFloat(result.toFixed(decimalScale));
        }

        conservedDecimalPlace = Number.parseInt(decimal.substring(decimalScale + 1, 1), 10);

        // Se a casa decimal que será CONSERVADA, for PAR
        if (conservedDecimalPlace > 0) {
            return Number.parseFloat(result.toFixed(decimalScale));
        }

        // Se APÓS a casa decimal SUBSEQUENTE,
        // não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO TRUNCA
        // (Esse é o único momento em que o "arredondamento ABNT" se diferencia do
        // "arredondamento normal")
        return truncate(value, decimalScale);
    }

    return value;
}

function truncate(value: number, decimalScale: number): number {
    const result = value;
    const split = value.toString().split('.');
    const decimal = split[1];

    if (decimalScale < 1 || !decimal || decimal.length <= decimalScale) {
        return result;
    }

    // Pega a parte inteira do número e concatena com a substring sem alterar
    // pois é PAR e vai manter
    const s = `${Number.parseInt(value.toString(), 10)}.${decimal.substring(0, decimalScale)}`;
    return Number.parseFloat(s);
}

export function getDecimalCount(value: number): number {
    const numberString = value.toString();

    // Check if the number has a decimal point
    if (numberString.includes('.')) {
        // Find the position of the decimal point and calculate the number of decimal places
        return numberString.split('.')[1]?.length || 0;
    }

    // If there is no decimal point, return 0
    return 0;
}

export function truncateDecimal(value: number, decimalPlaces: number): number {
    const power = 10 ** decimalPlaces;
    return Math.round(value * power) / power;
}
